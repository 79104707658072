import style from './Metagenomica.module.css'
import './Metagenomica.css'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Col, Container, Nav, Row, Table } from 'react-bootstrap'
import { TemaContext } from '../../common/context/TemaContext';
import { LinguagemContext } from '../../common/context/LinguagemContext';
import { UsuarioContext } from '../../common/context/UsuarioContext';
import { useNavigate} from 'react-router-dom';
import { useTranslation} from 'react-i18next';
import Translator from '../I18n/Translator';
import L from 'leaflet';
import { Box, Slider, TextField, Autocomplete, FormControl, InputLabel, Select, MenuItem, Chip, OutlinedInput, Stack} from '@mui/material';
import Moment from 'react-moment';
import 'chartjs-adapter-moment';
import {Line } from 'react-chartjs-2';
import PopupGrafico from '../PopupGrafico';
import Footer from '../Footer';
import moment from 'moment';
import { t, use } from 'i18next';
import chroma from 'chroma-js';
import { re } from 'mathjs';
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import * as XLSX from 'xlsx';
import TabNav from '../TabNav';

var cod_estados = [
    {"UF":"AC",
    "COD": 12, "NOME":"Acre"},
    {"UF":"AL",
    "COD": 27, "NOME":"Alagoas"},
    {"UF":"AP",
    "COD": 16, "NOME":"Amapá"},
    {"UF":"AM",
    "COD": 13, "NOME":"Amazonas"},
    {"UF":"BA",
    "COD": 29, "NOME":"Bahia"},
    {"UF":"CE",
    "COD": 23, "NOME":"Ceará"},
    {"UF":"DF",
    "COD": 53, "NOME":"Distrito Federal"},
    {"UF":"ES",
    "COD": 32, "NOME":"Espírito Santo"},
    {"UF":"GO",
    "COD": 52, "NOME":"Goiás"},
    {"UF":"MA",
    "COD": 21, "NOME":"Maranhão"},
    {"UF":"MT",
    "COD": 51, "NOME":"Mato Grosso"},
    {"UF":"MS",
    "COD": 50, "NOME":"Mato Grosso do Sul"},
    {"UF":"MG",
    "COD": 31, "NOME":"Minas Gerais"},
    {"UF":"PA",
    "COD": 15, "NOME":"Pará"},
    {"UF":"PB",
    "COD": 25, "NOME":"Paraíba"},
    {"UF":"PR",
    "COD": 41, "NOME":"Paraná"},
    {"UF":"PE",
    "COD": 26, "NOME":"Pernambuco"},
    {"UF":"PI",
    "COD": 22, "NOME":"Piauí"},
    {"UF":"RJ",
    "COD": 33, "NOME":"Rio de Janeiro"},
    {"UF":"RN",
    "COD": 24, "NOME":"Rio Grande do Norte"},
    {"UF":"RS",
    "COD": 43, "NOME":"Rio Grande do Sul"},
    {"UF":"RO",
    "COD": 11,"NOME":"Rondônia"},
    {"UF":"RR",
    "COD": 14,"NOME":"Roraima"},
    {"UF":"SC",
    "COD": 42,"NOME":"Santa Catarina"},        
    {"UF":"SP",
    "COD": 35,"NOME":"São Paulo"},
    {"UF":"SE",
    "COD": 28,"NOME":"Sergipe"},
    {"UF":"TO",
    "COD": 17,"NOME":"Tocantins"},
];

var posicao = localStorage.getItem('posicaoScroll');

const Metagenomica = () => {
    const {theme} = useContext(TemaContext);
    const {language} = useContext(LinguagemContext);
    const {logado} = useContext(UsuarioContext);

    const baseColors = ["#FFA500", "#FFFF00", "#00FF00", "#00CED1", "#1E90FF", "#00008B"];  // Paleta base      

    const { t } = useTranslation();
    const navigate = useNavigate();

    const position = [-15.0, -54.27390005057997];
    const defaultZoom = 3.00;
    const zoomSnap_ = 0.25;
    const wheelPxPerZoomLevel_ = 120;

    var alterandoData = false;

    var weightNormal = theme === "dark" ? 0.5 : 0.3;
    var weightBrasil = 1.5;

    var weightSelecao = 2.5; 

    const transparencia = useRef(0.5);

    var opacidadeMunicipio = 1.0;

    const transparenciaDefaultMapa = useMemo(() => {
        return "rgba(255, 255, 255, 0)";
    }, []);
    const cinzaDefaultMapaDark = useMemo(() => {
        return "#272727";
    }, []);
    const cinzaDefaultMapaBorda = useMemo(() => {
        return "#C1c3c3";//"rgba(221, 220, 223, 0.3)";
    }, []);

    const circles = useRef([]);  // useRef para armazenar os círculos no mapa
    const circlesOrigem = useRef([]);  // useRef para armazenar os círculos no mapa
    const polylines = useRef([]);  // useRef para armazenar os polylines no mapa

    const todasCidades = useRef([]);

    let isResponsive = true;

    let maintainAspectRatio = false;

    let leftGrafico = 0;
    let rightGrafico = 0;
    let topGrafico = 0;
    let bottomGrafico = 0;

    /*Variáveis Tabela*/
    const [campoOrdem, setCampoOrdem] = useState("rank_mob_den_ibp_dsei");
    const [decrescente, setDecrescente] = useState(false);
    const [campoOrdemOrigemDestino, setCampoOrdemOrigemDestino] = useState("estado_origem");
    const [decrescenteOrigemDestino, setDecrescenteOrigemDestino] = useState(false);

    const [semanaEp, setSemanaEp] = useState("");
    
    const [dadosTabRank, setDadosTabRank] = useState([]);

    const valorNil = "nil";

    var corLinhaMarcaoGrafico = (theme === "dark" ? "rgba(255, 255, 255, 1)" : "rgba(94, 94, 94, 1)");

    var texto_grafico_dark = "#fff";
    var texto_grafico_light = "#000";
    var fontGrafico = 10;

    const cacheCores = useRef([]);

    const fixaCasasDecimais = (valor, casasDecimais) => {
        if(valor === valorNil) 
            return valorNil;
        return parseFloat(valor).toFixed(casasDecimais).replace(',', '.');
    }

    const preparaInt = (valor) => {
        var valor_temp = valor + "";
        valor_temp = valor_temp.replace(".", "");
        valor_temp = valor_temp.replace(",", "");
        return valor_temp;
    }
    
    const trataNaNeNull = (dado) => {
        return dado === null || dado === "" || dado === "NaN" ? valorNil : dado;
    }
    
    const trataNaNeNullInt = (dado) => {
        return dado === null || dado === "" || dado === "NaN" ? valorNil : parseInt(dado);
    }

    function exibeMesAnoGrafico(index, values){
        const totalTicks = values.length;

        const date = moment(values[index].value);
        
        // Sempre mostre o primeiro e o último tick
        if (index === 0 || index === totalTicks - 1) {
            return `${FormatarDoisDigitos(date.isoWeek())}-${date.year()}`;
        }
        // Lógica para mostrar ticks intermediários
        let intermediateTicks = 6; // número de ticks intermediários desejados

        let totalWeekTicks = Math.floor(totalTicks / 7);

        if(totalWeekTicks < intermediateTicks)
            intermediateTicks = totalWeekTicks - 2;
            if(intermediateTicks < 0)
                intermediateTicks = 0;

        const stepSize = Math.floor(totalTicks / (intermediateTicks + 1));

        if (index % stepSize === 0) {
            return `${FormatarDoisDigitos(date.isoWeek())}-${date.year()}`;
        }

        return null; // Não mostra outros ticks
    }

    var geojsonBrasil = (require('./Dados/shapefile/BR_UF_2022.json'));
    var geojsonMunicipios = (require('./Dados/shapefile/BR_Municipios_2022.json'));

    /*Variáveis de Data*/
    const [semanaSelecionada, setSemanaSelecionada] = useState(0);
    const [inicioSemanaSelecionada, setInicioSemanaSelecionada] = useState(null);
    const [fimSemanaSelecionada, setFimSemanaSelecionada] = useState(null);
    const [inicioSemanaSelecionadaRange, setInicioSemanaSelecionadaRange] = useState(null);
    const [fimSemanaSelecionadaRange, setFimSemanaSelecionadaRange] = useState(null);
    const [semanas, setSemanas] = useState([]);
    const [inicioSemanas, setInicioSemanas] = useState([]);
    const [fimSemanas, setFimSemanas] = useState([]);
    const [idxDataSelecionada, setIdxDataSelecionada] = useState(0);
    const [dataSelecionada, setDataSelecionada] = useState(0);
    const [minDate, setMinDate] = useState(null);
    const [minDateRangeInicial, setMinDateRangeInicial] = useState(null);
    const [minDateRangeFinal, setMinDateRangeFinal] = useState(null);
    const [minIdxDate, setMinIdxDate] = useState(0);
    const [maxDate, setMaxDate] = useState(null);
    const [maxDateRangeInicial, setMaxDateRangeInicial] = useState(null);
    const [maxDateRangeFinal, setMaxDateRangeFinal] = useState(null);
    const [maxIdxDate, setMaxIdxDate] = useState(0);
    const [datas, setDatas] = useState([]);
    const [maxLengthData, setMaxLengthData] = useState(0);
    /*Fim Variáveis de Data*/

    const [indice, setIndice] = useState("mob");

    const legend = useRef(null);

    var layer_ = useRef(null);

    const geoRaster = useRef(null);
    
    const [dados_municipio_selecionado, setDados_Municipio_Selecionado] = useState(null);

    const min = useRef(0);
    const max = useRef(0);
    const range = useRef(0);
    
    posicao = localStorage.getItem('posicaoScroll');
    var nomes_estados = (require('./Dados/estados.json'));

    //refs mapa
    const layerTile = useRef(null);
    const layerControl = useRef(null);
    let map = useRef(null);

    //refs shapefiles
    const shapeFileBrasil = useRef(null);
    const shapeFileMunicipios = useRef(null);
    const polylineLayer = useRef(null);

    const iniciou = useRef(false);

    let val_Rank = [
        t("dashboard.mobilidade"),
        t("dashboard.densidade"),
        "IBP",
        "DSEI"
    ];

    var [selectedRank, setSelectedRank] = useState(val_Rank);

    function getOptions_Line(theme, perc, titulo){    
        return {
          responsive: isResponsive,
          maintainAspectRatio: maintainAspectRatio,
          layout:{
            padding: {
                left: leftGrafico,
                right: rightGrafico,
                top: topGrafico,
                bottom: bottomGrafico
            }
          },
          plugins: {
              annotation: {
                  annotations: [
                    {
                      type: 'line',
                      mode: 'vertical',
                      scaleID: 'x',
                      value: dataSelecionada,
                      borderColor: corLinhaMarcaoGrafico,
                      borderWidth: 1,
                    },
                  ],
                },
            tooltip: {
              callbacks: {
                /*label: context => {
                  context["formattedValue"] = perc ? fixaCasasDecimais(context["formattedValue"],2) + "%" : context["formattedValue"];
                  if(context["element"]["options"]["backgroundColor"] === "rgba(255, 255, 255, 1)")
                    return "";
  
                  return getDataLabelGrafico(context);
                }*/
              }
            },
            legend: {
              display: true,
              onClick: null,
              position: 'bottom',
              align: 'start',
              labels: {
                boxWidth: 15,
                color: theme === "dark" ? texto_grafico_dark : texto_grafico_light,
                font: { 
                  size: 10
                }
              }
            },
            title: {
              display: false,
              text: titulo,
              color: theme === "dark" ? texto_grafico_dark : texto_grafico_light,
              position: 'top',
              font:{
                size: 13
              }
            },
          },
          scales: {
            y: {  // not 'yAxes: [{' anymore (not an array anymore)  
              ticks: {
                color: theme === "dark" ? texto_grafico_dark : texto_grafico_light, // not 'fontColor:' anymore
                // fontSize: 18,
                font: {
                  size: fontGrafico, // 'size' now within object 'font {}'
                },
                //stepSize: passo,
                beginAtZero: false,
                callback: function(value, index, ticks) {
                  return value + (perc === true ? '%' : '');
                }
              },
              beginAtZero: false,
            },
            /*x: {  // not 'xAxes: [{' anymore (not an array anymore)
              type: 'time',
              time: {
                parser: 'YYYY/MM/DD',
                unit: 'day',
                displayFormats: {
                 'day': 'DD/MM/YYYY',
                 'month' : 'DD/MM/YYYY'
                },
                
              },
              ticks:{
                color: theme === "dark" ? texto_grafico_dark : texto_grafico_light,
                font: {
                  size: fontGrafico, // 'size' now within object 'font {}'
                },
                //stepSize: 7,
                callback: function(value, index, values) {
                    
                    return exibeMesAnoGrafico(index, values);
                },
              },
              min: datas != null ? datas[minIdxDate] : null,
              max: datas != null ? datas[maxIdxDate] : null,
            }*/
          }
          
        }
    }

    const handleChangeSelectData = (event) => {
        if(event.target.value > (datas.length -1)){
           /* console.log(inicioSemanas);*/
            setIdxDataSelecionada(datas.length -1);
            setDataSelecionada(datas[datas.length -1]);
            setSemanaSelecionada(semanas[semanas.length -1]);
            setInicioSemanaSelecionada(inicioSemanas[inicioSemanas.length -1]);
            setFimSemanaSelecionada(fimSemanas[fimSemanas.length -1]);
        } else {
            setIdxDataSelecionada(event.target.value);
            setDataSelecionada(datas[event.target.value]);
            setSemanaSelecionada(semanas[event.target.value]);
            setInicioSemanaSelecionada(inicioSemanas[event.target.value]);
            setFimSemanaSelecionada(fimSemanas[event.target.value]);
        }
    };

    const mudarData = (idx, datas_local) => {
        setLoading(true);
        if (alterandoData && !datas_local)
            return;

        if (idx > datas_local.length - 1) {
            idx = 0;
        } else if (idx < 0) {
            idx = datas_local.length - 1;
        }

        if(map.current  !== null)
            map.current.closePopup();

        alterandoData = true;

        setIdxDataSelecionada(idx);
        setDataSelecionada(datas_local[idx]);
        setSemanaSelecionada(semanas[idx]);
        setInicioSemanaSelecionada(inicioSemanas[idx]);
        setFimSemanaSelecionada(fimSemanas[idx]);

        alterandoData = false;
    }

    function getCidades(somenteNomes) {
        var nomes_municipios = [];

        if(somenteNomes){
            nomes_municipios.unshift(
                t("dashboard.todos")
            );
            nomes_municipios.sort((a, b) => a.localeCompare(b));
        } else {
            nomes_municipios.unshift({
                "cod_ibge": 0,
                "municipio": t("dashboard.todos")
            });
            nomes_municipios.sort((a, b) => a["municipio"].localeCompare(b["municipio"]));
        }

        return nomes_municipios;
    }

    const getEstados = () => { 
        let nomes_estados_tmp = [];
        
        for (let i=0; i < nomes_estados.length; i++){
            nomes_estados_tmp.push(nomes_estados[i]);
        }

        nomes_estados_tmp.unshift({
            "UF": "All",
            "ESTADO": t("dashboard.todos")
        });         
        
        return nomes_estados_tmp;
    }

    const data_colormap = useRef([]);
    var color_scale = null;
    let escala = "ºC";

    const [loading, setLoading] = useState(true);
    const [cod_ibge, setCod_Ibge] = useState(t("dashboard.todos"));
    const [municipioSelecionado, setMunicipioSelecionado] = useState(t("dashboard.todos"));
    const [estadoSelecionado, setEstadoSelecionado] = useState(t("dashboard.todos"));
    const [listaMunicipios, setListaMunicipios] = useState(getCidades());
    const [listaEstados, setListaEstados] = useState(getEstados());
    const [cidades_labels, setCidades_Labels] = useState([{cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")}]);
    const [mapa_cor_value, setMapa_Cor_Value] = useState("jet");

    const [dados_Municipio, setDados_Municipio] = useState(null);

    const [ultimoValorTEMP, setUltimoValorTEMP] = useState(null);
    const [ultimoValorUM, setUltimoValorUM] = useState(null);
    const [ultimoValorPREC, setUltimoValorPREC] = useState(null);

    const [dadosGenomica, setDadosGenomica] = useState(null);
    const [dadosOrigemDestino, setDadosOrigemDestino] = useState(null); // Dados JSON de Origem e Destino

    const [dataCache, setDataCache] = useState(null);

    const [dadosUFGenomica, setDadosUFGenomica] = useState(null);

    const [link_zika, setLink_Zika] = useState("https://aesoparbov.outerlamce.com/");
    var link_gripe = "#";
    let dengue = true;
    
    const defaultPropsMunicipio = {
        options: cidades_labels,
        getOptionLabel: (option) => option.label,
    };

    const isAllSelect = (variavel) => {
        return variavel === "All" || variavel === "Todos";
    }

    useEffect(() => {
        if(shapeFileMunicipios.current != null){
            plotaMapa();
            carregaCircles();
        }
    }, [cod_ibge]);

    function converterDiaDoAnoParaData(ano, diaDoAno) {
        var data = new Date(ano, 0); // Inicia em 0 (janeiro)
        data.setDate(diaDoAno); // Define o dia do ano
        
        // Formata a data para um formato legível
        var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        var dataFormatada = data.toLocaleDateString('pt-BR', options);
        
        return dataFormatada;
    }

/*
    useEffect(() => {
    
    }, [dados_Municipio]);
    */

   /*
    useEffect(() => {

    }, [mapa_cor_value]);
    */

    const getNomeEUFCidadeWhereCodIbge = (cod_ibge_local) => {
        let retorno = null;
        if(isNumeric(cod_ibge_local)){
            const keys = Object.keys(shapeFileMunicipios.current["_layers"]);
            if (keys.length > 0) {
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    const elementLoop = shapeFileMunicipios.current["_layers"][key];
                    if(elementLoop !== undefined){
                        let cod_temp = parseInt(elementLoop["feature"]["properties"]["CD_MUN"]);
                        if(elementLoop["feature"]["properties"]["CD_MUN"] === cod_ibge_local || cod_temp === cod_ibge_local){
                            retorno =  [elementLoop["feature"]["properties"]["NM_MUN"], elementLoop["feature"]["properties"]["SIGLA_UF"]];
                            break;
                        }
                    } else {
                        retorno = [cod_ibge_local, ""];
                    }
                }
            } 
        }
        else {
            retorno = cod_ibge_local;
        }
        return retorno;
    }

    const getNomeCidadeWhereCodIbge = (cod_ibge_local) => {
        let retorno = null;
        if(isNumeric(cod_ibge_local)){
            const keys = Object.keys(shapeFileMunicipios.current["_layers"]);
            if (keys.length > 0) {
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    const elementLoop = shapeFileMunicipios.current["_layers"][key];
                    if(elementLoop !== undefined){
                        let cod_temp = parseInt(elementLoop["feature"]["properties"]["CD_MUN"]);
                        if(elementLoop["feature"]["properties"]["CD_MUN"] === cod_ibge_local || cod_temp === cod_ibge_local){
                            retorno =  elementLoop["feature"]["properties"]["NM_MUN"];
                            break;
                        }
                    } else {
                        retorno = cod_ibge_local;
                    }
                }
            } 
        }
        else {
            retorno = cod_ibge_local;
        }
        return retorno;
    }

    const getCodIbgeShapefile = (shapefile, shape) => {
        let valor = shapefile["_layers"][shape]["feature"]["properties"]["CD_MUN"];
        if(typeof shapefile["_layers"][shape]["feature"]["properties"]["CD_MUN"] === "number"){
            valor = shapefile["_layers"][shape]["feature"]["properties"]["CD_MUN"].toString();
        }
        return (valor);
    }

    const handleChangeMunicipioNome = (nome_municipio) => {
        if (map.current !== null)
            map.current.closePopup();

        /*console.log("handleChangeMunicipioNome: ");*/

        if(isAllSelect(nome_municipio)){
            Object.keys(shapeFileMunicipios.current["_layers"]).forEach((shape) => {
                let corBordaAnt = shapeFileMunicipios.current["_layers"][shape]["options"]["color"];
                if(corBordaAnt === "#dddcdf"){
                    corBordaAnt = "black"; 
                }
                shapeFileMunicipios.current["_layers"][shape].setStyle({ weight: weightNormal, color: corBordaAnt, fillOpacity: 1.0 });
            });

            setCod_Ibge(nome_municipio);
            setMunicipioSelecionado(getNomeCidadeWhereCodIbge(nome_municipio));
            setDados_Municipio_Selecionado(null);
        } else {
            setCod_Ibge(nome_municipio);
            setMunicipioSelecionado(getNomeCidadeWhereCodIbge(nome_municipio));
            
            const keys = Object.keys(shapeFileMunicipios.current["_layers"]);
            if (keys.length > 0) {
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    const elementLoop = shapeFileMunicipios.current["_layers"][key];
                    if (nome_municipio === getCodIbgeShapefile(shapeFileMunicipios.current, key)) {
                        map.current.fitBounds(elementLoop.getBounds().pad(1));
                        break;
                    }
                }
            } 
        }
    };

    function calculateControlPoint(lat1, lon1, lat2, lon2, curvatura = 0.3) {
        // Passo 1: Calcular o ponto médio
        const lat_mid = (lat1 + lat2) / 2;
        const lon_mid = (lon1 + lon2) / 2;
      
        // Passo 2: Calcular a diferença entre as coordenadas
        const dx = lon2 - lon1;
        const dy = lat2 - lat1;
      
        // Distância aproximada entre os pontos
        const distance = Math.sqrt(dx * dx + dy * dy);
      
        // Passo 3: Calcular o vetor perpendicular normalizado
        const perp_dx = -dy;
        const perp_dy = dx;
      
        const magnitude = Math.sqrt(perp_dx * perp_dx + perp_dy * perp_dy);
      
        // Passo 4: Ajustar o vetor perpendicular pela curvatura e distância
        const offset_x = (perp_dx / magnitude) * distance * curvatura;
        const offset_y = (perp_dy / magnitude) * distance * curvatura;
      
        // Passo 5: Calcular o ponto de controle deslocado
        const lat_control = lat_mid + offset_y;
        const lon_control = lon_mid + offset_x;
      
        return [lat_control, lon_control];
    }

    const bezierCurve = (start, control, end, segments = 200) => {
        const curve = [];
        for (let i = 0; i <= segments; i++) {
          const t = i / segments;
          const x =
            Math.pow(1 - t, 2) * start[0] +
            2 * (1 - t) * t * control[0] +
            Math.pow(t, 2) * end[0];
          const y =
            Math.pow(1 - t, 2) * start[1] +
            2 * (1 - t) * t * control[1] +
            Math.pow(t, 2) * end[1];
          curve.push([x, y]);
        }
        return curve;
      };

    var estados_labels = [
        {label:t("dashboard.todos")},
        {label:"Acre"},
        {label:"Alagoas"},
        {label:"Amapá"},
        {label:"Amazonas"},
        {label:"Bahia"},
        {label:"Ceará"},
        {label:"Distrito Federal"},
        {label:"Espírito Santo"},
        {label:"Goiás"},
        {label:"Maranhão"},
        {label:"Mato Grosso"},
        {label:"Mato Grosso do Sul"},
        {label:"Minas Gerais"},
        {label:"Pará"},
        {label:"Paraíba"},
        {label:"Paraná"},
        {label:"Pernambuco"},
        {label:"Piauí"},
        {label:"Rio de Janeiro"},
        {label:"Rio Grande do Norte"},
        {label:"Rio Grande do Sul"},
        {label:"Rondônia"},
        {label:"Roraima"},
        {label:"Santa Catarina"},        
        {label:"São Paulo"},
        {label:"Sergipe"},
        {label:"Tocantins"},
    ];

    var defaultProps = {
        options: estados_labels,
        getOptionLabel: (option) => option.label,
    };

    var zoomPorClique = false;

    useEffect(() => {     
        /*if (!logado) {
            navigate("/");
            return;
        }*/

        var dominio = window.location.hostname;


        /*//// Use fetch para carregar o arquivo JSON
        fetch('Dados/dados_pais/dados_brasil.json')
        .then(response => response.json())
        .then(data => {
            setDados_Municipios(data); // Atribui os dados JSON à variável global
        })
        .catch(error => {
            console.error('Erro ao carregar o arquivo JSON:', error);
        });

        getSemana();*/

        fetch('Dados/genomic.json') // Use fetch para carregar o arquivo JSON
        .then(response => response.json())
        .then(data => {
            setDadosGenomica(data); // Atribui os dados JSON à variável global
            setDadosTabRank(data);
            setSemanaEp(data[0]["epiweek"] + "-" + data[0]["epiyear"]);
        })
        .catch(error => {
            console.error('Erro ao carregar o arquivo JSON:', error);
        });

        fetch("Dados/ori_des_muni_warning.json")
        .then((response) => response.json())
        .then((data) => {
            setDadosOrigemDestino(data);
            //console.log(data);
        }).catch(error => {
            console.error('Erro ao carregar o arquivo JSON:', error);
        });

        fetch('Dados/UF_genomic.json') // Use fetch para carregar o arquivo JSON
        .then(response => response.json())
        .then(data => {
            setDadosUFGenomica(data); // Atribui os dados JSON à variável global
            //console.log("carregou UF", data);
        })
        .catch(error => {
            console.error('Erro ao carregar o arquivo JSON:', error);
        });

        if (shapeFileBrasil.current === null) {
            shapeFileBrasil.current = L.geoJSON(geojsonBrasil, {
                interactive: false,
                style: function (feature) {
                    return { color: "black", fillColor: transparenciaDefaultMapa, stroke: true, weight: weightNormal, fillOpacity: transparencia.current };
                },
                /*onEachFeature: onEachFeatureBrasil,*/
            });
        }

        if (shapeFileMunicipios.current === null) {
            shapeFileMunicipios.current = L.geoJSON(geojsonMunicipios, {
                style: function (feature) {
                    return { color: "gray", fillColor: transparenciaDefaultMapa, stroke: true, weight: weightNormal, fillOpacity: opacidadeMunicipio };
                },
                onEachFeature: onEachFeatureEstado,
            });
        }

        iniciou.current = true;
        localStorage.setItem('posicaoScroll', JSON.stringify(0));
        //getDadosPorData(datas[idxDataSelecionada], language, true);
        //setDados_Municipios(dados_municipios_semanal);

        setDataCache(localStorage.getItem('dataCacheFiles')); 

        fetch('Dados/lista_municipios/lista_municipios_todos.json?' 
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
        .then(function (response) {
            //Limpa pontos anteriores
            return response.json();
        })
        .then(function (myJson) {
            todasCidades.current = myJson;
        });

        var dominio = window.location.hostname;

        if(dominio.includes("arbov")){
            dengue = false;
        } else {
            dengue = true;
        }

        if(dominio.includes("aesopdev")){
            setLink_Zika("https://aesoparbovdev.outerlamce.com/");
        } else if(dominio.includes("testeaesop.")) {
            setLink_Zika("https://testeaesoparbov.outerlamce.com/");
        } else {
            setLink_Zika("https://aesoparbov.outerlamce.com/");
        }

        if(dominio.includes("aesoparbovdev")){
            link_gripe = "https://aesopdev.outerlamce.com/";
        } else if(dominio.includes("teste")) {
            link_gripe = "https://testeaesop.outerlamce.com/";
        } else {
            link_gripe = "https://aesop.outerlamce.com/";
        }
        
    }, []);

    window.onscroll = function (e) {
        posicao = window.scrollY;
        localStorage.setItem('posicaoScroll', JSON.stringify(posicao));
    }

    useEffect(() => {
        if(!iniciou.current)
            return;

        var url = theme === "dark" ? "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}" : "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}";

        const { innerWidth: width, innerHeight: height } = window;

        layerTile.current = L.tileLayer(url, {
            zoomControl: true,
            zoomSnap: zoomSnap_,
            zoomDelta: zoomSnap_,
            wheelPxPerZoomLevel: wheelPxPerZoomLevel_,
            defaultZoom: defaultZoom,
            maxZoom: 18,
            id: 'mapbox.streets'
        });

        if (map.current === null) {
            map.current = L.map("map", {
                minZoom: 4,
                maxZoom: 10,
                layers: [layerTile.current]
            });

            shapeFileMunicipios.current.addTo(map.current);
            shapeFileBrasil.current.addTo(map.current);
        } else {
            map.current.invalidateSize();
        }

        const defaultCenter = position;

        if(width < 1300)
            map.current.setView([-13.338793, -45.206666], defaultZoom);
        else 
            map.current.setView(defaultCenter, defaultZoom);

        if(shapeFileMunicipios.current === null){
            shapeFileMunicipios.current = L.tileLayer('');
        }

        if(polylineLayer.current === null){
            polylineLayer.current = L.layerGroup().addTo(map.current);
        }

        const lineCoordinates = [
            [-22.890090, -42.029405], // Latitude e longitude do ponto inicial
            [-12.924167, -38.440701],  // Latitude e longitude do ponto final
          ];

        const lineCoordinates2 = [
            [-23.62340166156419, -46.65858576087318], // Latitude e longitude do ponto inicial
            [-12.988999467983497, -38.50386516595166],  // Latitude e longitude do ponto final
          ];

        if (map.current) {
            // Adiciona um ouvinte de evento para o evento de zoomend
            const onZoomEnd = (event) => {
              // Obtém o nível de zoom atual
              const currentZoom = event.target.getZoom();

              if(theme === "dark"){
                if(currentZoom <=  5){
                    weightNormal = 0.5;
                }  else {
                    weightNormal = 1;
                }
              }
              //console.log(currentZoom);
      
            };
      
            // Adiciona o ouvinte de evento ao mapa para o evento de zoomend
            map.current.on('zoomend', onZoomEnd);
        } 
        
    }, [map]);

    useEffect(() => {
        if ((map.current === null))
            return;

        if (layerTile.current !== null) {
            map.current.removeLayer(layerTile.current);
        }

        var url = theme === "dark" ? "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}" : "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}";

        const defaultCenter = position;

        layerTile.current = L.tileLayer(url, {
            zoomControl: true,
            zoomSnap: zoomSnap_,
            zoomDelta: zoomSnap_,
            wheelPxPerZoomLevel: wheelPxPerZoomLevel_,
            defaultZoom: defaultZoom,
            maxZoom: 18,
            id: 'mapbox.streets'
        });
        /*map.current.setView(defaultCenter, defaultZoom);*/
        layerTile.current.addTo(map.current);

        carregaCircles();

    },[theme]);

    useEffect(() => {/*
        if(max.current === 0 && min.current === 0){

        } else {
            data_colormap.current = calcularFracoes(min.current, max.current);
            preparaColorMap();
        }
        if(isAllSelect(estadoSelecionado)){
            setEstadoSelecionado(t("dashboard.todos"));
        }

        estados_labels[0] = {label:t("dashboard.todos")};

        if(isAllSelect(municipioSelecionado)){
            setListaMunicipios(getCidades());
            setMunicipioSelecionado(t("dashboard.todos"));
            let cidades_labels_tmp = [{cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")}];
            setCidades_Labels(cidades_labels_tmp);
        }*/

        if(isAllSelect(estadoSelecionado)){
            setEstadoSelecionado(t("dashboard.todos"));
        }

        estados_labels[0] = {label:t("dashboard.todos")};

        if(isAllSelect(municipioSelecionado)){
            setListaMunicipios(getCidades());
            setMunicipioSelecionado(t("dashboard.todos"));
            let cidades_labels_tmp = [{cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")}];
            setCidades_Labels(cidades_labels_tmp);
        }

        setListaEstados(getEstados());
  
        val_Rank = [
            t("dashboard.mobilidade"),
            t("dashboard.densidade"),
            "IBP",
            "DSEI"
        ];

    }, [language]);

    // Função para interpolar entre duas cores em formato RGB
    function interpolateColor(value, minRank, maxRank) {
        // Define as cores de início e fim em formato RGB
        const startColor = [63, 169, 245]; // Azul claro (RGB para #ADD8E6)
        const endColor = [20, 24, 61];     // Azul arroxeado (RGB para #483D8B)

        // Normaliza o valor entre 0 e 1 com base no rank mínimo e máximo
        const normalizedValue = (value - minRank) / (maxRank - minRank);

        // Interpola as cores
        const r = Math.round(startColor[0] + (endColor[0] - startColor[0]) * normalizedValue);
        const g = Math.round(startColor[1] + (endColor[1] - startColor[1]) * normalizedValue);
        const b = Math.round(startColor[2] + (endColor[2] - startColor[2]) * normalizedValue);

        // Retorna a cor interpolada em formato CSS RGB
        return `rgb(${r}, ${g}, ${b})`;
    }

    // Função para remover todos os círculos do mapa
    function removeAllCircles() {
        if (circles.current.length > 0) {
            circles.current.forEach(circle => circle.remove());
            circles.current = [];  // Limpa o array após remover os círculos
        }
    }

    function removeAllCirclesOrigem() {
        if (circlesOrigem.current.length > 0) {
           /* circlesOrigem.current.forEach(circle => circle.remove());*/
            circlesOrigem.current = [];  // Limpa o array após remover os círculos
        }
    }

    function removeAllPolylines() {
        /*if (polylines.current.length > 0) {
            polylines.current.forEach(polyline => polyline.remove());
            polylines.current = [];  // Limpa o array após remover os polylines
        }*/
        //console.log("removeAllPolylines");
        polylines.current.forEach(function (item) {
            map.current.removeLayer(item)
        });
        polylineLayer.current.clearLayers();
      }

    // Função para calcular o raio dos círculos com base no zoom
    function calculateCircleRadius(baseRadius, zoomLevel) {
        /*if(zoomLevel > 8) {
            return baseRadius; // Não ajusta o raio se o zoom for maior que 8
        }*/
        return (baseRadius * 10) / Math.pow(2, zoomLevel - 3); // Ajuste o fator de escala conforme necessário
    }

    //crie uma função que receba o valor máximo e minimo, valor atual e 5 cores, retorne a cor correspondente
    function getColor(value, min, max, colors) {
        const range = max - min;
        const step = range / (colors.length - 1);
        const index = Math.floor((value - min) / step);
        return colors[index];
    }

    /*
    useEffect(() => {
            
    }, [map, dadosGenomica]); */

    function getCodIbgeLayerShapefile(layer) {
        return (layer["feature"]["properties"]["CD_MUN"]);
    }

    const getCodEstadoBySigla = (sigla) => {
        for(let i = 0; i < cod_estados.length; i++){
            if(cod_estados[i]["UF"] === sigla){
                return cod_estados[i]["COD"];
            }
        }
    }

    const isNumeric = (value) => {
        return /^\d+(?:\.\d+)?$/.test(value); 
    }

    function carregaMunicipiosPorEstado(siglaEstado){
        if(!loading){
            setLoading(true);
        }
        /*console.log("carregaMunicipiosPorEstado");*/
        if(siglaEstado !== ''){
            fetch('Dados/lista_municipios/lista_municipios_'+siglaEstado+'.json'
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
            .then(function (response) {
                //Limpa pontos anteriores
                return response.json();
            })
            .then(function (myJson) {
                let listaTemp = [];
                let cidades_labels_tmp = [];
                for(let i = 0; i < myJson.length; i++){
                    listaTemp.push({
                        "cod_ibge": myJson[i]["COD_IBGE"],
                        "municipio": myJson[i]["MUNICIPIO"],
                        "latitude": myJson[i]["Y"],
                        "longitude": myJson[i]["X"]
                    });

                    cidades_labels_tmp.push({cod_ibge: myJson[i]["COD_IBGE"],  label: myJson[i]["MUNICIPIO"]});
                }

                cidades_labels_tmp.sort((a, b) => a.label.localeCompare(b.label));

                cidades_labels_tmp.unshift({cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")});//todos

                setCidades_Labels(cidades_labels_tmp);

                listaTemp.sort((a, b) => a["municipio"].localeCompare(b["municipio"]));
                
                listaTemp.unshift({
                    "cod_ibge": 0,
                    "municipio": t("dashboard.todos")
                });

                setListaMunicipios(listaTemp); 

                //getDadosPorData(datas[idxDataSelecionada], language, true);
            });
        }
    }

    const getNomeEstadoByCodIbge = (cod_ibge_local) => {
        let retorno = null;
        if(isNumeric(cod_ibge_local) && shapeFileBrasil.current !== null && shapeFileBrasil.current["_layers"] !== null){
            const keys = Object.keys(shapeFileBrasil.current["_layers"]);
            if (keys.length > 0) {
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    const element = shapeFileBrasil.current["_layers"][key];
                    if(element !== undefined){
                        let cod_temp = parseInt(element["feature"]["properties"]["CD_UF"]);
                        if(element["feature"]["properties"]["CD_UF"] === cod_ibge_local || cod_temp === cod_ibge_local){
                            retorno =  element["feature"]["properties"]["NM_UF"];
                            break;
                        } 
                    } else {
                        retorno = cod_ibge_local;
                    }
                }
            }  
        }
        else {
            retorno = cod_ibge_local;
        }
        return retorno;
    }

    function FormatarDoisDigitos(numero) {
        if(numero === null || numero === undefined)
            return "00";
        return numero.toString().padStart(2, '0');
    }

    function extraiAno(data){
        if(data && data.length > 0)
            return data.substring(0,4);
        else 
            return "";
    }

    function getDadosGenomica(){
        return dadosGenomica;
    }

    function onEachFeatureEstado(feature, layer) {
        layer.on({
            /*mouseover: function (e) {
                var layer = e.target;
                // Adiciona um popup com o nome do município

                if (feature.properties && feature.properties.NM_MUN) {
                    
                    let valor_ranking = 0;
                    let nome_var = getNomeCampoIndice(indice);
                    if(getDadosGenomica() !== null){
                        console.log("passei aqui");
                        for (let i = 0; i < getDadosGenomica() .length; i++) {
                            if(feature.properties.CD_MUN === getDadosGenomica()[i]["cod_ibge"]){
                                valor_ranking = getDadosGenomica()[i][nome_var];
                                break;
                            }
                        }
                    }
                    
                    layer.bindPopup(feature.properties.NM_MUN + " - " + feature.properties.SIGLA_UF + "<br> Ranking: " + valor_ranking, {
                        className: "popup-estilo"
                    }).openPopup();
                }
            },
            mouseout: function (e) {
                var layer = e.target;
                //shapeFileMunicipios.resetStyle(layer);
                layer.closePopup();
            },*/
            click: () => {
                if(cod_ibge === getCodIbgeLayerShapefile(layer)){
                    map.current.fitBounds(layer.getBounds().pad(1));
                    return;
                }

                setLoading(true);
   
                //resetShapeFile(estadoSelecionado, municipioSelecionado, true);

                let siglaEstadoSelecionada = feature["properties"]["SIGLA_UF"];

                let codEstadoSelecionado = getCodEstadoBySigla(siglaEstadoSelecionada);

                let nomeEstadoSelecionado = getNomeEstadoByCodIbge(codEstadoSelecionado);

                if(estadoSelecionado !== nomeEstadoSelecionado){
                    carregaMunicipiosPorEstado(siglaEstadoSelecionada);

                    setEstadoSelecionado(nomeEstadoSelecionado);
                }

                setMunicipioSelecionado(layer['feature']['properties']['NM_MUN']/*getNomeCidadeWhereCodIbge(getCodIbgeLayerShapefile(layer))*/);
                setCod_Ibge(getCodIbgeLayerShapefile(layer));

                layer.setStyle({ weight: weightSelecao, color: "black", fillOpacity: opacidadeMunicipio  });
                zoomPorClique = true;
                map.current.fitBounds(layer.getBounds().pad(1));
            },
            
        });
    }

    useEffect(() => {
        let siglaEstado = '';
        let codigo_estado = 0;

        if(shapeFileBrasil.current !== null){
            Object.keys(shapeFileBrasil.current["_layers"]).forEach((shape) => {
                if(isAllSelect(estadoSelecionado)){
                    shapeFileBrasil.current["_layers"][shape].setStyle({ weight: weightNormal, color: /*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaBrasilWhite*/"black" , fillColor: /*'#E63100'*//*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaWhite*/ transparenciaDefaultMapa, fillOpacity: 0.5 });
                } else
                if(shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["NM_UF"] === estadoSelecionado){ 
                    siglaEstado = shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["SIGLA_UF"];
                    codigo_estado = shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["CD_UF"];
                    shapeFileBrasil.current["_layers"][shape].setStyle({ weight: 2, color: "black" /*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaWhite*//*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaBrasilWhite/*transparenciaDefaultMapa*/, fillColor: /*'#E63100'*//*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaWhite*/ transparenciaDefaultMapa, fillOpacity: 0.5 });
                } else {
                    shapeFileBrasil.current["_layers"][shape].setStyle({ weight: weightNormal, color: /*transparenciaDefaultMapa*/"black", fillColor: /*transparenciaDefaultMapa*/ "black", fillOpacity: transparencia.current });   
                }
            });
        }

        if(isAllSelect(estadoSelecionado) && (!isAllSelect(municipioSelecionado) || isAllSelect(municipioSelecionado))){
            const defaultCenter = position;
            const { innerWidth: width, innerHeight: height } = window;
            if(width < 1300)
                map.current.setView([-13.338793, -45.206666], defaultZoom);
            else 
                map.current.setView(defaultCenter, defaultZoom);
        }

        if(isAllSelect(estadoSelecionado) && !isAllSelect(municipioSelecionado)){
            setMunicipioSelecionado(t("dashboard.todos"));
        }

        if(!isAllSelect(municipioSelecionado)){
            return;
        }

        setLoading(true);

        if(shapeFileBrasil.current !== null){
            if(codigo_estado === 0){
                setListaMunicipios(getCidades());
                let cidades_labels_tmp = [{cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")}];
                setCidades_Labels(cidades_labels_tmp);
            }
            
            carregaDadosEstado(codigo_estado, siglaEstado);
        }

        cacheCores.current = [];

        console.log("passei aqui 2", estadoSelecionado, municipioSelecionado);
           
        carregaCircles();

        setTimeout(function () {    
            setLoading(false);
        }, 100);
    }, [estadoSelecionado, municipioSelecionado, indice]);

    function carregaDadosEstado(codigo_estado, siglaEstado){
        if(!loading){
            setLoading(true);
        }
        
        if(codigo_estado !== 0){

            const keys = Object.keys(shapeFileBrasil.current["_layers"]);
            if (keys.length > 0) {
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    const element = shapeFileBrasil.current["_layers"][key];
                    if(element !== undefined && element["feature"]["properties"]["NM_UF"] === estadoSelecionado){
                        map.current.fitBounds(element.getBounds());
                        break;
                    } 
                }
            } 

            carregaMunicipiosPorEstado(siglaEstado);
        } 
    }

    const plotaMapa = () => {
        Object.keys(shapeFileMunicipios.current["_layers"]).forEach((shape) => {
            var cod_ibge_tmp = getCodIbgeShapefile(shapeFileMunicipios.current, shape);
            let transparenciaFill = /*theme === "dark" ? "#8d8d8f" : "#e5e5e6"*/"rgba(255,255,255,0)";
            
            var cor = transparenciaFill;
            var corContorno = theme === "dark" ? cinzaDefaultMapaDark : cinzaDefaultMapaBorda;
    
            let weight_ = weightNormal;
            
            if (cod_ibge === shapeFileMunicipios.current["_layers"][shape]["feature"]["properties"]["CD_MUN"]) {
                cor = transparenciaFill;
                corContorno = /*theme === "dark" ? cinzaDefaultMapaDark : cinzaDefaultMapaBorda*/"black";
                weight_ = weightSelecao;     
            }

            shapeFileMunicipios.current["_layers"][shape].setStyle({ weight: weight_, color: corContorno, fillColor: cor, fillOpacity: opacidadeMunicipio});                                
        });   

        setLoading(false); 
    }

    const handleChangeIndicador = (event) => {
        setIndice(event.target.value);
    }

    const [listaSinaisSoc, setlistaSinaisSoc] = useState(
        [
            {"sinal": "mob", "nome":  t("dashboard.mobilidade")},
            /*{"sinal": "den", "nome":  t("dashboard.densidade")},
            {"sinal": "ibp", "nome":  t("dashboard.ibp")},
            {"sinal": "dsei", "nome": t("dashboard.dsei")},*/
        ]
    );

    const getNomeCampoIndice = (indice) => {
        let nome_var = "rank_mob_den_ibp_dsei";
        
        if(selectedRank.length === 4){
            nome_var = "rank_mob_den_ibp_dsei";
        } else if(selectedRank.includes(t("dashboard.densidade")) && selectedRank.includes(t("dashboard.ibp"))){
            nome_var = "rank_mob_den_ibp";
        } else if(selectedRank.includes(t("dashboard.densidade")) && selectedRank.includes(t("dashboard.dsei"))){
            nome_var = "rank_mob_den_dsei";
        } else if(selectedRank.includes(t("dashboard.ibp")) && selectedRank.includes(t("dashboard.dsei"))){
            nome_var = "rank_mob_ibp_dsei";
        } else if(selectedRank.includes(t("dashboard.densidade"))){
            nome_var = "rank_mob_den";
        } else if(selectedRank.includes(t("dashboard.ibp"))){
            nome_var = "rank_mob_ibp";
        } else if(selectedRank.includes(t("dashboard.dsei"))){
            nome_var = "rank_mob_dsei";
        } else {
            nome_var = "rank_mob";
        }

        return nome_var;
    };

      // Função para converter uma cor hexadecimal para componentes RGB
    function hexToRgb(hex) {
        if(hex === null || hex === undefined)
        return {r: 0, g: 0, b: 0};
        //console.log(hex);
        let bigint = parseInt(hex.substring(1), 16);
        return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255
        };
    }

    // Função para converter componentes RGB de volta para hexadecimal
    function rgbToHex(r, g, b) {
        return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
    }
    
    // Função para interpolar duas cores com base em um fator
    function interpolateColor(color1, color2, factor) {
        let c1 = hexToRgb(color1);
        let c2 = hexToRgb(color2);
        
        let r = Math.round(c1.r + factor * (c2.r - c1.r));
        let g = Math.round(c1.g + factor * (c2.g - c1.g));
        let b = Math.round(c1.b + factor * (c2.b - c1.b));
        
        return rgbToHex(r, g, b);
    }
    
    // Função para gerar uma paleta de cores dinâmica de acordo com a diferença entre min e max
    function generateColorArray(min, max, numColors) {
        //baseColors = baseColors.reverse();
        let colors = [];
    
        let numBaseColors = baseColors.length - 1;
        for (let i = 0; i < numColors; i++) {
        // Proporção da interpolação
        let factor = i / (numColors - 1);
        
        // Determinar em qual intervalo de baseColors estamos interpolando
        let index = Math.floor(factor * numBaseColors);
        let subFactor = (factor * numBaseColors) - index;
    
        // Interpolar entre as duas cores base adjacentes
        colors.push(interpolateColor(baseColors[index], baseColors[index + 1], subFactor));
        }
    
        return colors;
    }
    
    // Função principal para retornar uma cor interpolada com base no valor
    function getColorForValue(value, min, max) {
        // Definindo o número de cores baseado na diferença de min e max
        let numColors = Math.abs(max - min) + 1;  // Número de cores dependendo da diferença min/max
        
        // Gera o array de cores interpolado
        //const colors = generateColorArray(min, max, numColors);
        let color_scale = chroma.scale(baseColors);

        let paletaTemporariaCores = color_scale.colors(5); 

        let min_ = data_colormap.current["min"]["valor"];
        let q1 = data_colormap.current["q1"]["valor"];
        let q2 = data_colormap.current["q2"]["valor"];
        let q3 = data_colormap.current["q3"]["valor"];
        let q4 = data_colormap.current["q4"]["valor"];
        let max_ = data_colormap.current["max"]["valor"];

  
        if(value >= min_ && value < q1){
            return baseColors[0];
        } else if(value >= q1 && value < q2){
            return baseColors[1];
        } else if(value >= q2 && value < q3){
            return baseColors[2];
        } else if(value >= q3 && value < q4){
            return baseColors[3];
        }else if(value >= q4 && value <= max_){
            return baseColors[4];
        }

        //console.log("value", value, min, max, min_, q1, q2, q3, max_);

        /*// Garantir que o valor esteja dentro dos limites
        if (value < min) value = min;
        if (value > max) value = max;
    
        // Determina a posição do valor no array de cores
        let index = Math.floor((value - min) / (max - min) * (paletaTemporariaCores.length - 1));
    
        // Retorna a cor correspondente
        return paletaTemporariaCores[index];*/
        return "red";;
    }

    function percentile(arr, p) {
        if (arr.length === 0) return null;
        const sortedArr = arr.slice().sort((a, b) => a - b);
        const index = (p / 100) * (sortedArr.length - 1);
        const lower = Math.floor(index);
        const upper = Math.ceil(index);
        if (lower === upper) return sortedArr[index];
        const weight = index - lower;
        return sortedArr[lower] * (1 - weight) + sortedArr[upper] * weight;
      }
      
      function quartile(arr) {
        return [
          percentile(arr, 25), // Primeiro quartil (Q1)
          percentile(arr, 50), // Mediana (Q2)
          percentile(arr, 75)  // Terceiro quartil (Q3)
        ];
      }

      function quintile(arr) {
        return [
          percentile(arr, 20), // Primeiro quintil (Q1)
          percentile(arr, 40), // Segundo quintil (Q2)
          percentile(arr, 60), // Terceiro quintil (Q3)
          percentile(arr, 80)  // Quarto quintil (Q4)
        ];
      }

    const getDadosUF = (cod_ibge) => {
        for(let i = 0; i < dadosUFGenomica.length; i++){
            if(dadosUFGenomica[i]["cod_ibge"] === cod_ibge){
                return dadosUFGenomica[i];
            }
        }
    }

    const carregaCircles = () => {
        if(map.current === null || dadosGenomica === null)
            return;

        let nome_var = getNomeCampoIndice(indice);
        /*if(indice === "mob"){
            if(selectedRank.length === 3){
                nome_var = "rank_mob_den_ibp_dsei";
            } else if(selectedRank.includes(t("dashboard.densidade")) && selectedRank.includes(t("dashboard.ibp"))){
                nome_var = "rank_mob_den_ibp";
            } else if(selectedRank.includes(t("dashboard.densidade")) && selectedRank.includes(t("dashboard.dsei"))){
                nome_var = "rank_mob_den_dsei";
            } else if(selectedRank.includes(t("dashboard.ibp")) && selectedRank.includes(t("dashboard.dsei"))){
                nome_var = "rank_mob_ibp_dsei";
            } else if(selectedRank.includes(t("dashboard.densidade"))){
                nome_var = "rank_mob_den";
            } else if(selectedRank.includes(t("dashboard.ibp"))){
                nome_var = "rank_mob_ibp";
            } else if(selectedRank.includes(t("dashboard.dsei"))){
                nome_var = "rank_mob_dsei";
            } else {
                nome_var = "rank_mob";
            }
        } else  if (indice === "den") {
            nome_var = "rank_den";
        } else  if (indice === "ibp"){
            nome_var = "rank_ibp";
        } else if (indice === "dsei"){
            nome_var = "rank_dsei";
        }*/

        if (map.current !== null && dadosGenomica !== null) {
            // Remova todos os círculos existentes
            removeAllCircles();
            removeAllCirclesOrigem();

            const createCircle = (x, y, color, fillColor, adjustedRadius, nome_municipio, valor) => {
                // Adiciona novo círculo ao mapa
                const circle = L.circle([y, x], {
                    interactive: false,
                    color: color,
                    fillColor: fillColor,
                    fillOpacity: valor === 0 ? 1 : 0.7,
                    radius: adjustedRadius,
                    weight: color === "white" ? 0.8 : 1
                }).addTo(map.current);

                // Adicione um popup ao círculo (opcional)
                //circle.bindPopup(nome_municipio + "<br>" + nome_var+": " + dadosGenomica[i][nome_var]);
                
                /*circle.on('mouseover', function (e) {
                    //circle.openPopup();
                    var layer = e.target;
                    // Adiciona um popup com o nome do município
                    let texto = nome_municipio[0] + " - " + nome_municipio[1];
                    if(valor > 0){
                        texto += "<br> Ranking: " + valor;
                    }
                    layer.bindPopup(texto, {
                        className: "popup-estilo-metagenomica"
                    }).openPopup();

                    
                });

                circle.on('mouseout', function (e) {
                    circle.closePopup();
                });*/

                return circle;
            }
    
            // Função para adicionar círculos ajustados ao mapa
            const addCircles = () => {
                const zoomLevel = map.current.getZoom();
                const isCacheColor = cacheCores.current.length > 0;
                if(!isAllSelect(estadoSelecionado)){
                    removeAllPolylines();

                    let minRank = 0;
                    let maxRank = 0;
                    let codEstado = 0;

                    let tempDadosUFGenomica = [];
                    for (let i = 0; i < dadosUFGenomica.length; i++) {

                        let nomeEstadoTemp = getNomeEstadoByCodIbge(dadosUFGenomica[i]["uf_data_to_filter"]);
                        if(estadoSelecionado !== nomeEstadoTemp){
                            continue;
                        } else if(codEstado === 0){
                            codEstado = dadosUFGenomica[i]["uf_data_to_filter"];
                        }

                        if(minRank > dadosUFGenomica[i][nome_var]){
                            minRank = dadosUFGenomica[i][nome_var];
                        }

                        if(maxRank < dadosUFGenomica[i][nome_var]){
                            maxRank = dadosUFGenomica[i][nome_var];
                        }  
                        tempDadosUFGenomica.push(dadosUFGenomica[i]);
                    }
                    
                    setDadosTabRank(tempDadosUFGenomica);

                    //console.log(tempDadosUFGenomica);

                    let municipios = {};
                    let routes = [];
                    circlesOrigem.current = [];

                    for(let j=0; j < dadosOrigemDestino.length; j++){
                        if(dadosOrigemDestino[j]["ori_co_ibge"].toString().substring(0,2) === codEstado)
                            routes.push({"from": dadosOrigemDestino[j]["ori_co_ibge"], "to": dadosOrigemDestino[j]["cod_ibge_muni"]});
                    }

                    for(let i=0; i < dadosUFGenomica.length; i++){
                        
                        if(dadosUFGenomica[i]["cod_ibge"].toString().substring(0,2) === codEstado){
                            if(dadosUFGenomica[i][nome_var] > maxRank)
                                maxRank = dadosUFGenomica[i][nome_var];
                            if(dadosUFGenomica[i][nome_var] < minRank)
                                minRank = dadosUFGenomica[i][nome_var];
                        }
                        
                        municipios[dadosUFGenomica[i]["cod_ibge"]] = [dadosUFGenomica[i]["centroid_y"], dadosUFGenomica[i]["centroid_x"]];
                        /*for(let j=0; j < dadosOrigemDestino.length; j++){
                        if(dadosGenomica[i]["cod_ibge"] === dadosOrigemDestino[j]["ori_co_ibge"]){
                            //console.log("origem", dadosGenomica[i]["cod_ibge"]);
                            origens.push({"cod_ibge": dadosGenomica[i]["cod_ibge"], "y": dadosGenomica[i]["centroid_y"], "x": dadosGenomica[i]["centroid_x"]});
                            break;
                        }
                        if(dadosGenomica[i]["cod_ibge"] === dadosOrigemDestino[j]["cod_ibge_muni"]){
                            console.log("destino", dadosGenomica[i]["cod_ibge"]);
                            destinos.push({"cod_ibge": dadosGenomica[i]["cod_ibge"], "y": dadosGenomica[i]["centroid_y"], "x": dadosGenomica[i]["centroid_x"]});
                            break;
                        }
                        }*/
                    }
                    
                    //console.log("plota polilynes ", estadoSelecionado);
                    // Loop "for" para iterar sobre as rotas
                    for (let i = 0; i < routes.length; i++) {
                        
                        let start = municipios[routes[i]["from"]];
                        const end = municipios[routes[i]["to"]];

                        if(!start && !end){
                            //console.log("não encontrou", routes[i]["from"], routes[i]["to"]);
                            continue;
                        } else if(!start) {
                            for (let x = 0; x < todasCidades.current.length; x++) {
                                if(todasCidades.current[x]["COD_IBGE"] === routes[i]["from"]){
                                    start = [todasCidades.current[x]["Y"], todasCidades.current[x]["X"], true];
                                    let criaCirculo = true;
                                    //se route[i]["from"] não for encontrado em circlesOrigem, adicione
                                    for(let y=0; y < circlesOrigem.current.length; y++){
                                        if(circlesOrigem.current[y] === routes[i]["from"]){
                                            criaCirculo = false;
                                            break;
                                        }
                                    }
                                    if(criaCirculo){
                                        //console.log("criaCirculo", routes[i]["from"]);
                                        const baseRadius = (((maxRank/2)/maxRank) * 1500) * 10; // Raio base do círculo
                                        const adjustedRadius = calculateCircleRadius(baseRadius, zoomLevel); // Ajusta o raio com base no zoom
                                        const circle = createCircle(todasCidades.current[x]["X"], todasCidades.current[x]["Y"], "white", "black", adjustedRadius, [todasCidades.current[x]["MUNICIPIO"], todasCidades.current[x]["UF"]], 0);
                                        circles.current.push(circle);
                                    }
                                    break;
                                }
                            }
                            circlesOrigem.current.push(routes[i]["from"]);
                            if(!start){
                                //console.log(start, "não encontrou", routes[i]["from"]);
                                continue;
                            }
                        }

                        // Calcular o ponto de controle
                        const control = calculateControlPoint(start[0], start[1], end[0], end[1]);

                        // Gerar o caminho da curva de Bézier
                        const bezierPath = bezierCurve(start, control, end);

                        // Adicionar a polyline ao mapa usando Leaflet
                        var polyline = L.polyline(bezierPath, {
                            color: theme === "dark" ? 'white' : 'black',
                            weight: 0.8,
                            opacity: 0.3
                        });

                        polylineLayer.current.addLayer(polyline);
                        polylines.current.push(polyline);
                    }

                    //extraia os quintis do dadosUFGenomica[i][nome_var]
                    let quintis = quintile(dadosUFGenomica.map(d => d[nome_var]));

                    //crie a estrutura do data_colormap
                    data_colormap.current = {'min': {'valor': 1}, 
                    'max': {'valor': maxRank}, 
                    'q1': {'valor': quintis[0]}, 
                    'q2': {'valor': quintis[1]}, 
                    'q3': {'valor': quintis[2]},
                    'q4': {'valor': quintis[3]}};

                    for (let i = 0; i < dadosUFGenomica.length; i++) {
                        let rankValue = maxRank-dadosUFGenomica[i][nome_var];

                        let nomeEstadoTemp = getNomeEstadoByCodIbge(dadosUFGenomica[i]["uf_data_to_filter"]);
                        if(estadoSelecionado !== nomeEstadoTemp){
                            continue;
                        }   

                        let isOrigem = false;   
                        for(let x=0; x < circlesOrigem.current.length; x++){
                            //console.log(typeof(circlesOrigem.current[x]), circlesOrigem.current[x], typeof(dadosUFGenomica[i]["cod_ibge"]), dadosUFGenomica[i]["cod_ibge"], circlesOrigem.current[x] === dadosUFGenomica[i]["cod_ibge"]);
                            if(circlesOrigem.current[x] === dadosUFGenomica[i]["cod_ibge"]){
                                isOrigem = true;
                                break;
                            }
                        }
                        let corBorda = isOrigem ? "black" : "";

                        let circleColor = "";
                        /*if(isCacheColor){
                            for(let x = 0; x < cacheCores.current.length; x++){
                                if(cacheCores.current[x]["rank"] === dadosUFGenomica[i][nome_var]){
                                    //console.log("cache");
                                    circleColor = cacheCores.current[x]["cor"];
                                    corBorda = cacheCores.current[x]["fill"];
                                    break;
                                }
                            }
                        }
                        else {*/
                            circleColor = isOrigem ? "black" : getColorForValue(dadosUFGenomica[i][nome_var]/*dadosUFGenomica[i][nome_var]*/, minRank, maxRank);
                            cacheCores.current.push({"rank":dadosUFGenomica[i][nome_var], "cor": circleColor, "fill": corBorda !== "" ? corBorda : circleColor});
                        //}
                        
                        //console.log(zoomLevel);
                        //console.log(dadosUFGenomica[i][nome_var], "rank", rankValue, "minRank", minRank, "maxRank", maxRank, "valor", ((rankValue/maxRank) * 1500) * 10);
                        const baseRadius = ((rankValue/maxRank) * 1500) * 10; // Raio base do círculo
                        const adjustedRadius = calculateCircleRadius(baseRadius, zoomLevel); // Ajusta o raio com base no zoom

                        //se ajustedRadius for nan ou infinito, não plote
                        if(isNaN(adjustedRadius) || !isFinite(adjustedRadius)){
                            continue;
                        }
                        
                        //pesquise no shapefile de municipios o cod_ibge e pegue o nome do municipio
                        let nome_municipio = getNomeEUFCidadeWhereCodIbge(dadosUFGenomica[i]["cod_ibge"]);

                        console.log(nome_municipio[0], nome_municipio[1], dadosUFGenomica[i][nome_var], maxRank - rankValue, baseRadius, adjustedRadius, circleColor, getColorForValue(dadosUFGenomica[i][nome_var]/*dadosUFGenomica[i][nome_var]*/, minRank, maxRank), data_colormap.current);

                        const circle = createCircle(dadosUFGenomica[i]["centroid_x"], dadosUFGenomica[i]["centroid_y"], isOrigem ? "black" : circleColor, corBorda !== "" ? corBorda : circleColor, adjustedRadius, nome_municipio, dadosUFGenomica[i][nome_var]);

                        // Adicione o círculo ao array de referências
                        circles.current.push(circle);
                    }
                } else  if(dadosGenomica){
                    setDadosTabRank(dadosGenomica);
                    let minRank = Math.min(...dadosGenomica.map(d => d[nome_var]));
                    let maxRank = Math.max(...dadosGenomica.map(d => d[nome_var]));
     
                    //extraia os quintis do dadosUFGenomica[i][nome_var]
                    let quintis = quintile(dadosGenomica.map(d => d[nome_var]));

                    //crie a estrutura do data_colormap
                    data_colormap.current = {'min': {'valor': 1}, 
                    'max': {'valor': maxRank}, 
                    'q1': {'valor': quintis[0]}, 
                    'q2': {'valor': quintis[1]}, 
                    'q3': {'valor': quintis[2]}, 
                    'q4': {'valor': quintis[3]}};

                    for (let i = 0; i < dadosGenomica.length; i++) {
                        let rankValue = dadosGenomica[i][nome_var];

                        let circleColor = "";
                        if(isCacheColor){
                            for(let x = 0; x < cacheCores.current.length; x++){
                                if(cacheCores.current[x]["rank"] === dadosGenomica[i][nome_var]){
                                    //console.log("cache");
                                    circleColor = cacheCores.current[x]["cor"];
                                    break;
                                }
                            }
                        }
                        else 
                        {
                            circleColor = getColorForValue(dadosGenomica[i][nome_var], minRank, maxRank);
                            cacheCores.current.push({"rank":dadosGenomica[i][nome_var], "cor":circleColor});
                        }
   
                        const baseRadius = ((maxRank - rankValue) * 5) * 10; // Raio base do círculo
                        const adjustedRadius = calculateCircleRadius(baseRadius, zoomLevel); // Ajusta o raio com base no zoom

                        //se ajustedRadius for nan ou infinito, não plote
                        if(isNaN(adjustedRadius) || !isFinite(adjustedRadius)){
                            continue;
                        }
                        
                        //pesquise no shapefile de municipios o cod_ibge e pegue o nome do municipio
                        let nome_municipio = getNomeEUFCidadeWhereCodIbge(dadosGenomica[i]["cod_ibge"]);

                        // Adiciona novo círculo ao mapa
                        const circle = L.circle([dadosGenomica[i]["centroid_y"], dadosGenomica[i]["centroid_x"]], {
                            color: circleColor,
                            fillColor: circleColor,
                            fillOpacity: 0.7,
                            radius: adjustedRadius,
                            weight: 1
                        }).addTo(map.current);
                       
                       /*circle.on('mouseover', function (e) {
                            //circle.openPopup();
                            var layer = e.target;
                            // Adiciona um popup com o nome do município                        
                            layer.bindPopup(nome_municipio[0] + " - " + nome_municipio[1] + "<br> Ranking: " + dadosGenomica[i][nome_var], {
                                className: "popup-estilo-metagenomica"
                            }).openPopup();  
                        });

                        circle.on('mouseout', function (e) {
                            circle.closePopup();
                        });*/

                        // Adicione o círculo ao array de referências
                        circles.current.push(circle);
                    }
                }
            };
    
            // Adiciona os círculos inicialmente
            addCircles();
    
            // Atualiza os círculos quando o mapa for ampliado ou reduzido
            map.current.on('zoomend', () => {
                removeAllPolylines();
                removeAllCircles();
                removeAllCirclesOrigem();
                addCircles();
            });

            Object.keys(shapeFileMunicipios.current["_layers"]).forEach((shape) => {
                let dados = null;
                let zeraValor = false;
                if(!isAllSelect(estadoSelecionado)){
                    dados = dadosUFGenomica;
                    zeraValor = true;
                }else{
                    dados = dadosGenomica;
                }
                let encontrou = false;
                for (let i = 0; i < dados.length; i++) {
                    if (parseInt(getCodIbgeShapefile(shapeFileMunicipios.current, shape)) === parseInt(dados[i]["cod_ibge"])) {
                        let conteudo = shapeFileMunicipios.current["_layers"][shape]["feature"]["properties"]["NM_MUN"] + " - " + shapeFileMunicipios.current["_layers"][shape]["feature"]["properties"]["SIGLA_UF"] + "<br> Ranking: " + dados[i][nome_var];
                        
                        if(shapeFileMunicipios.current["_layers"][shape].getPopup()){
                            shapeFileMunicipios.current["_layers"][shape].getPopup().setContent(conteudo);
                            shapeFileMunicipios.current["_layers"][shape].getPopup().update();
                        } else {
                            shapeFileMunicipios.current["_layers"][shape].bindPopup(conteudo, {autoPan:false, className: 'popup-estilo-metagenomica'});
                        }

                        shapeFileMunicipios.current["_layers"][shape].on('mouseover', function(e){ 
                            var popup = e.target.getPopup();
                            popup.setLatLng(e.latlng).openOn(map.current); 
                        });
                        shapeFileMunicipios.current["_layers"][shape].on('mousemove', function(e){ 
                            var popup = e.target.getPopup();
                            popup.setLatLng(e.latlng).openOn(map.current);
                        });
                        shapeFileMunicipios.current["_layers"][shape].on('mouseout', function(){ 
                            this.closePopup(); 
                        });
                        encontrou = true;
                        break;
                    }
                }
                if(!encontrou){
                    shapeFileMunicipios.current["_layers"][shape].unbindPopup();
                    shapeFileMunicipios.current["_layers"][shape].off('mouseover');
                    shapeFileMunicipios.current["_layers"][shape].off('mousemove');
                    shapeFileMunicipios.current["_layers"][shape].off('mouseout');
                }
            });
    
           /*// Remove o listener de zoom quando o componente é desmontado
            return () => {
                map.current.off('zoomend');
            };*/
        }
        preparaColorMap();
    }

    function handleChangeEstadoTabela (estado_selecionado){
        setLoading(true);
        setEstadoSelecionado(estado_selecionado); 
        setCod_Ibge(t("dashboard.todos"));
        setMunicipioSelecionado(t("dashboard.todos"));
    }

    const handleChangeMunicipioTabela = (cod_ibge) => {
        if (map.current !== null)
            map.current.closePopup();
        if(!isAllSelect(estadoSelecionado)){
            setCod_Ibge(cod_ibge.toString());
            setMunicipioSelecionado(getNomeCidadeWhereCodIbge(cod_ibge.toString()));
            Object.keys(shapeFileMunicipios.current["_layers"]).forEach((shape) => {
                if (cod_ibge.toString() === getCodIbgeShapefile(shapeFileMunicipios.current, shape)) {
                    shapeFileMunicipios.current["_layers"][shape].setStyle({ weight: weightSelecao, color: "black", fillOpacity: 1.0 });
                    map.current.fitBounds(shapeFileMunicipios.current["_layers"][shape].getBounds().pad(1));
                }
            });
        } else {
            let ufEstado = "";
            for(let i=0; i < cod_estados.length; i++){
                if(cod_ibge.toString().startsWith(cod_estados[i]["COD"])){
                    ufEstado = cod_estados[i]["UF"];
                    break;
                }
            }
            if(ufEstado !== ""){
                for(let i = 0; i < listaEstados.length; i++){
                    if(listaEstados[i]["UF"] === ufEstado){
                        setLoading(true);
                        //selecionaMunicipio.current = cod_ibge.toString();
                        setTimeout(() => {
                            setEstadoSelecionado(listaEstados[i]["ESTADO"]);
                        },1000);
                        break;
                    }
                }
            }
        }
    }

    const tabelaOrdenadaOrigemDestino = () => {
        //var tMakers = dadosOrigemDestino === null ? [] : [...dadosOrigemDestino] ;
        var tMakers = [];
     
        if(dadosOrigemDestino === null || dadosOrigemDestino.length === 0){
            return [];
        }

        if(!isAllSelect(estadoSelecionado)){
            for(let i=0; i < dadosOrigemDestino.length; i++){
                if(dadosOrigemDestino[i]["estado_origem"] === estadoSelecionado){
                    tMakers.push(dadosOrigemDestino[i]);
                }
            }
        } else {
            tMakers = dadosOrigemDestino === null ? [] : [...dadosOrigemDestino] ;
        }

        /*for(let i=0; i < tMakers.length; i++){
            let icodEstado = parseInt(tMakers[i]["cod_ibge_muni"].toString().substring(0,2));
            tMakers[i]["municipio_origem"] = getNomeCidadeWhereCodIbge(tMakers[i]["cod_ibge_muni"]);
            tMakers[i]["cod_ibge_origem"] = tMakers[i]["cod_ibge_muni"];
            tMakers[i]["estado_origem"] = getNomeEstadoByCodIbge(icodEstado);
            icodEstado = parseInt(tMakers[i]["ori_co_ibge"].toString().substring(0,2));
            tMakers[i]["municipio_destino"] = getNomeCidadeWhereCodIbge(tMakers[i]["ori_co_ibge"]);
            tMakers[i]["cod_ibge_destino"] = tMakers[i]["ori_co_ibge"];
            tMakers[i]["estado_destino"] = getNomeEstadoByCodIbge(icodEstado);
        } */ 
    
        for(let i=0; i < tMakers.length; i++){
            let icodEstado = parseInt(tMakers[i]["ori_co_ibge"].toString().substring(0,2));
            tMakers[i]["municipio_origem"] = getNomeCidadeWhereCodIbge(tMakers[i]["ori_co_ibge"]);
            tMakers[i]["cod_ibge_origem"] = tMakers[i]["ori_co_ibge"];
            tMakers[i]["estado_origem"] = getNomeEstadoByCodIbge(icodEstado);

            icodEstado = parseInt(tMakers[i]["cod_ibge_muni"].toString().substring(0,2));
            tMakers[i]["municipio_destino"] = getNomeCidadeWhereCodIbge(tMakers[i]["cod_ibge_muni"]);
            tMakers[i]["cod_ibge_destino"] = tMakers[i]["cod_ibge_muni"];
            tMakers[i]["estado_destino"] = getNomeEstadoByCodIbge(icodEstado);
        }

        var campoOrdemLocal = campoOrdemOrigemDestino;
        if (campoOrdemLocal === "sinal_ens_ivas") {
            if (decrescenteOrigemDestino) {
                tMakers.sort(function (a, b) { return b[campoOrdemLocal] - a[campoOrdemLocal]; });
            } else {
                tMakers.sort(function (a, b) { return a[campoOrdemLocal] - b[campoOrdemLocal]; });
            }
        }
        else
            if (campoOrdemLocal === "municipio_origem" || campoOrdemLocal === "municipio_destino" || campoOrdemLocal === "estado_origem" || campoOrdemLocal === "estado_destino") {
                if (decrescenteOrigemDestino) {
                    tMakers.sort((a, b) => (b[campoOrdemLocal].localeCompare(a[campoOrdemLocal])));
                } else {
                    tMakers.sort((a, b) => (a[campoOrdemLocal].localeCompare(b[campoOrdemLocal])));
                }
            } else {
                if (decrescenteOrigemDestino) {
                    tMakers.sort((a, b) => (a[campoOrdemLocal] > b[campoOrdemLocal]) ? -1 : 1);
                } else {
                    tMakers.sort((a, b) => (a[campoOrdemLocal] > b[campoOrdemLocal]) ? 1 : -1);
                }
            }

        if (posicao) {
            setTimeout(function () {
                window.scrollTo(0, posicao);
            }, 1);
        }

        //setLoading(false);
        return tMakers;
    }


    const tabelaOrdenada = () => {
        var tMakers = dadosTabRank === undefined ? [] : [...dadosTabRank] ;
     
        if(tMakers.length === 0 || tMakers.undefined){
            return [];
        }

        for(let i=0; i < tMakers.length; i++){
            let icodEstado = parseInt(tMakers[i]["cod_ibge"].toString().substring(0,2));
            tMakers[i]["municipio"] = getNomeCidadeWhereCodIbge(tMakers[i]["cod_ibge"]);
            tMakers[i]["estado"] = getNomeEstadoByCodIbge(icodEstado);
            if(tMakers[i]["dqi"] === null){
                tMakers[i]["dqi"] = "Não Apto";
            }
            /*if(tMakers[i]["dqi"] === null){
                console.log(tMakers[i]);
            }*/
        } 
        
        var campoOrdemLocal = campoOrdem;
        if (campoOrdemLocal === "sinal_ens_ivas") {
            if (decrescente) {
                tMakers.sort(function (a, b) { return b[campoOrdemLocal] - a[campoOrdemLocal]; });
            } else {
                tMakers.sort(function (a, b) { return a[campoOrdemLocal] - b[campoOrdemLocal]; });
            }
        }
        else
            if (campoOrdemLocal === "municipio" || campoOrdemLocal === "estado" || campoOrdemLocal === "dqi") {
                if (decrescente) {
                    tMakers.sort((a, b) => (b[campoOrdemLocal].localeCompare(a[campoOrdemLocal])));
                } else {
                    tMakers.sort((a, b) => (a[campoOrdemLocal].localeCompare(b[campoOrdemLocal])));
                }
            } else {
                if (decrescente) {
                    tMakers.sort((a, b) => (a[campoOrdemLocal] > b[campoOrdemLocal]) ? -1 : 1);
                } else {
                    tMakers.sort((a, b) => (a[campoOrdemLocal] > b[campoOrdemLocal]) ? 1 : -1);
                }
            }

        if (posicao) {
            setTimeout(function () {
                window.scrollTo(0, posicao);
            }, 1);
        }
    
        //setLoading(false);
        return tMakers;
    }

    useEffect(() => {
        
        setLoading(true);
        cacheCores.current = [];
        /*if(isAllSelect(estadoSelecionado) ){*/
            console.log("passei aqui", dadosGenomica, dadosUFGenomica, indice, selectedRank);
            carregaCircles();
        /*}voltar aqui*/
        //carregaCircles();
        setTimeout(function () {    
            setLoading(false);
        }, 100);

        if(campoOrdem.includes("rank")){
            setCampoOrdem(getNomeCampoIndice());
        }
    }, [dadosGenomica, dadosUFGenomica, indice, selectedRank]);

    function exportToExcel(nomeTabela, nomeArquivo) {
        const htmlTable = document.getElementById(nomeTabela);
        if(htmlTable === null)
            return
        const tableCopy = htmlTable.cloneNode(true);

        // Modifique a cópia da tabela para remover caracteres indesejados
        cleanTableData(tableCopy);

        let dataAtual = new Date();
        let dataSelecionada = dataAtual.toLocaleString('pt-BR', { timeZone: 'UTC' });
        //faça a saida de dataSelecionada para o formato desejado dd-mm-yyyy
        dataSelecionada = dataSelecionada.replace(/\//g, "-");
        dataSelecionada = dataSelecionada.substring(0, 10);

        var nomeArquivoLocal = dataSelecionada.replace(" ", "");
        nomeArquivoLocal = nomeArquivo.replace("-", "_");
        nomeArquivoLocal = nomeArquivo.replace("-", "_");
        nomeArquivoLocal = nomeArquivo.replace("00:00:00", "");

        const wb = XLSX.utils.table_to_book(tableCopy);
        XLSX.writeFile(wb, 'tabela_'+nomeArquivoLocal+'_'+nomeArquivo+'.xlsx');
    }

    function exportToCSV(nomeTabela, nomeArquivo) {
        const htmlTable = document.getElementById(nomeTabela);
        if(htmlTable === null)
            return;

        console.log(htmlTable);
        const rows = htmlTable.querySelectorAll('tr');

        let csv = '';
        for (const row of rows) {
            const cells = row.querySelectorAll('th,td');
            const rowData = Array.from(cells).map(cell => cell.textContent.replace('↑', ''));
            csv += rowData.join(',') + '\n';
        }

        let dataAtual = new Date();
        let dataSelecionada = dataAtual.toLocaleString('pt-BR', { timeZone: 'UTC' });
        //faça a saida de dataSelecionada para o formato desejado dd-mm-yyyy sem a hora
        dataSelecionada = dataSelecionada.replace(/\//g, "-");
        dataSelecionada = dataSelecionada.substring(0, 10);

        //remove a hora
        var nomeArquivoLocal = dataSelecionada.replace(" ", "");
        nomeArquivoLocal = nomeArquivoLocal.replace("-", "_");
        nomeArquivoLocal = nomeArquivoLocal.replace("-", "_");
        nomeArquivoLocal = nomeArquivoLocal.replace("00:00:00", "");

        const blob = new Blob([csv], { type: 'text/csv;charset=iso-8859-1;'});
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'tabela_'+nomeArquivoLocal+'_'+nomeArquivo+'.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    function cleanTableData (table) {
        // Obtém todas as células da tabela
        const cells = table.querySelectorAll('td');
    
        // Itera sobre cada célula e remove caracteres indesejados
        cells.forEach((cell) => {
          // Substitua 'caractere-indesejado' pelo caractere que você deseja remover
          const cleanedText = cell.textContent.replace(/↑/g, '');
    
          // Atualiza o conteúdo da célula com o texto limpo
          cell.textContent = cleanedText;
        });
    };

    function preparaColorMap() {

        var color_scale = chroma.scale(baseColors);


        if (map.current === null || data_colormap.current == [] || data_colormap.current == null || data_colormap.current == undefined)
            return;        

        const generateColors = (colorPalette) => {
            if (legend.current !== null) {
                map.current.removeControl(legend.current );
                legend.current  = null;
            }

            legend.current  = L.control({ position: "bottomleft" });

            legend.current.onAdd = function (map) {
                var div = null;
                div = L.DomUtil.create("div", "legend_");

                div.innerHTML += "<div>";

                let trTitulos = "<tr>";
                let trValores = "<tr>";
                function colorArrayElements(color, index, array) {
                    if(index === array.length - 1)
                        return;
                    var valMin = 0;
                    var valMax = 0;
                    var valQ1 = 0;
                    var valQ2 = 0;
                    var valQ3 = 0;
                    var valQ4 = 0;

                    valMin = parseFloat(data_colormap.current['min']['valor']).toFixed(0);
                    valMax = parseFloat(data_colormap.current['max']['valor']).toFixed(0);

                    valQ1 = parseFloat(data_colormap.current['q1']['valor']).toFixed(0);
                    valQ2 = parseFloat(data_colormap.current['q2']['valor']).toFixed(0);
                    valQ3 = parseFloat(data_colormap.current['q3']['valor']).toFixed(0);
                    valQ4 = parseFloat(data_colormap.current['q4']['valor']).toFixed(0);

                    let stringTitulo = "";

                    switch (index) {
                        case array.length - 1:
                            stringTitulo = '  ' + valMax;
                            break;
                        case 0:
                            stringTitulo = '  ' + valMin;
                            break;
                        case 4:
                            stringTitulo = '  ' + valQ4;
                            break;
                        case 3:
                            stringTitulo = '  ' + valQ3;
                            break;
                        case 2:
                            stringTitulo = '  ' + valQ2;
                            break;
                        case 1:
                            stringTitulo = '  ' + valQ1;
                            break;
                    }
                    let td = "<td  style=\"min-width: 55px; text-align: center; padding-right: 5px; padding-left: 5; background: "+color+";\" >&nbsp</td>";
                    //let td = "<td  style=\"min-width: 55px; text-align: center; padding-right: 5px; padding-left: 5; background: "+color+"; background: linear-gradient(90deg, " + array[index] + ", " + array[index + 1] + ");\" >&nbsp</td>";
                    let tdTitulo = "<td style=\"min-width: 55px; text-align: left; padding-right: 5px; padding-left: 0\">" + stringTitulo + "</td>";
                    trTitulos += " " + tdTitulo;
                    trValores += " " + td;                    
                }

                let paletaTemporariaCores = colorPalette.colors(6); //.reverse();
                paletaTemporariaCores.forEach(colorArrayElements);
                trTitulos += "</tr>";
                trValores += "</tr>";


                let descricaoVariavel = t("dashboard.ranking");

                div.innerHTML += "<center><div><h6 class=\"titulo-legend\">" + descricaoVariavel/*t("dashboard.legenda")*/ +" </h6></div></center>";
                div.innerHTML += "<table class=\"tableEscala\">" + trTitulos + trValores + "</table>";
                return div;
            };
            
            legend.current.addTo(map.current); 
        };

        generateColors(color_scale);       
    }

    return (
        <Container className="container_Mapa">
            {loading && <div className={style.loading}><div className={style.lds_ring}><div></div><div></div><div></div><div></div></div></div>}

            <Row>
                <TabNav theme={theme} language={language} currentPage={"Metagenomica"} link_gripe="" link_zika= {link_zika}/>

                <Row>
                    <div className={style.div_top}>
                        <br></br>

                        <div className={style.container_seleciona_municipio_e_cor}>
                            <div className={style.seleciona_municipio}> 
                                <Autocomplete
                                    {...defaultProps}
                                    disableClearable
                                    id="autocomplete_estado"
                                    className='change_color_text'
                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                    value={{label:estadoSelecionado}}
                                    onChange={(event, newValue) => {
                                        setLoading(true);
                                        removeAllPolylines();
                                        setEstadoSelecionado(newValue.label);
                                        setCod_Ibge(t("dashboard.todos"));
                                        setMunicipioSelecionado(t("dashboard.todos"));
                                    }}
                                    renderInput={(params) => (
                                        <TextField className='autocomplete_estado_textfield' {...params} label={t("dashboard.estado")} size="small"/>
                                    )}
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                
                                <Autocomplete
                                    {...defaultPropsMunicipio}
                                    disableClearable
                                    id="autocomplete_municipio"
                                    className='change_color_text'
                                    isOptionEqualToValue={(option, value) => option.cod_ibge === value.cod_ibge}
                                    value={{cod_ibge:cod_ibge, label:municipioSelecionado}}
                                    onChange={(event, newValue) => {
                                        handleChangeMunicipioNome(newValue.cod_ibge);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t("dashboard.cidade")} size="small"/>
                                    )}
                                />
                                
                                <FormControl id="multipleselect_rank_form" style={{display:indice === "mob" ? "block" : "none"}} >
                                    <InputLabel>{t("dashboard.titulo_ranking")}</InputLabel>
                                    <Select
                                        id="multipleselect_rank_select"
                                        multiple
                                        value={selectedRank}
                                        sx={{ width: 400}}
                                        onChange={(e) => setSelectedRank(e.target.value)}
                                        input={<OutlinedInput label={t("dashboard.titulo_ranking")} />}
                                        renderValue={(selected) => (
                                            <Stack id="multipleselect_rank_stack" gap={1} direction="row" flexWrap="wrap">
                                                {selected.map((value) => (
                                                <Chip
                                                    id="multipleselect_rank_chip"
                                                    key={value}
                                                    label={value}
                                                    sx={theme === "dark" ? { color: "white", backgroundColor: "#2f3136" } : {  }}
                                                    onDelete={() => {
                                                            
                                                            /*setSelectedRank(
                                                                selectedRank.filter((item) => item !== value))*/
                                                            if(value !== t("dashboard.mobilidade")){
                                                                setSelectedRank(
                                                                    selectedRank.filter((item) => item !== value || item === t("dashboard.mobilidade"))
                                                                );
                                                            }
                                                        }
                                                    }
                                                    deleteIcon={
                                                        <CancelIcon
                                                            id="multipleselect_rank_cancelicon"
                                                            onMouseDown={(event) => event.stopPropagation()}
                                                        />
                                                    }
                                                />
                                                ))}
                                            </Stack>
                                        )}
                                        
                                    >
                                        {val_Rank.map((name) => (
                                            name !== t("dashboard.mobilidade") && (
                                        <MenuItem
                                            key={name}
                                            value={name}
                                            sx={{ justifyContent: "space-between"}}
                                        >
                                            {name}
                                            {selectedRank.includes(name) ? <img src='imagens/check.png' style={{width:'15px', height:'15px'}} /> : null}
                                        </MenuItem>
                                        )))}
                                    </Select>
                                    
                                </FormControl>

                            </div>
                        </div>
                    </div>
                </Row>
                <div className='espacamento'></div> 
                <div className={style.semanaep}>
                    <Translator path="dashboard.semana_ep"/> {semanaEp}
                </div>
                <Row>
                    <Col md={12}>
                        <div className={style.mapa}>
                            <div id="map" className={style.map}>

                            </div>
                        </div>
                    </Col>
                </Row>
                <div className='espacamento'></div> 
                <div className={style.semanaep}>
                    <Translator path="dashboard.semana_ep" /> {semanaEp}
                </div>
                <div className='espacamento'></div>

                <div className={style.table}>
                    <div className={style.header_tabela}>
                        <div className={style.titulo_tabela}><h5><center>{!isAllSelect(estadoSelecionado) ? t("dashboard.table.titulo_rank_uf") : t("dashboard.table.titulo_rank")}</center></h5></div>
                        <div className={style.export_buttons}>
                            <div className='divExportPlanilhaCSV'>
                                <img className="exportPlanilhaCSV" src='/imagens/csv_logo.gif' onClick={() => {exportToCSV("dtBasicExampleRankExport", "rank")}}></img>
                                <div className="balaoDeTextoCsv noHover">Download CSV</div>
                            </div>
                            <div className={style.div_export_planilha_excel}>
                                <img className={style.export_planilha} src='/imagens/excel_logo.gif' onClick={() => {exportToExcel("dtBasicExampleRankExport", "rank")}}></img>
                                <div className={style.balao_de_texto_excel}>Download XLSX</div>        
                            </div>
                        </div>
                    </div>
                    <Table id={"dtBasicExampleRank"} striped bordered hover variant={theme === "dark" ? "dark" : ""}>
                        <thead>
                            <tr>
                                <th><a href='#' style={{ color: campoOrdem === "estado" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdem === "estado") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                        setCampoOrdem("estado")
                                    }}>
                                    <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> <Translator path={"dashboard.table.estado"} /></a>
                                </th>

                                <th><a href='#' style={{ color: campoOrdem === "municipio" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdem === "municipio") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                        setCampoOrdem("municipio")
                                    }}>
                                    <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> <Translator path={"dashboard.table.col1"} /></a>
                                </th>

                                <th><a href='#' style={{ color: campoOrdem === getNomeCampoIndice(indice) ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdem === getNomeCampoIndice(indice)) { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                        setCampoOrdem(getNomeCampoIndice(indice))
                                    }}> 
                                    <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.ranking"} /> </a>
                                </th>

                                <th><a href='#' style={{ color: campoOrdem === "dqi" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "dqi") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("dqi")
                                    }}> 
                                    <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> DQI </a>
                                </th>

                                <th><a href='#' style={{ color: campoOrdem === "sinal_ens_ivas" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdem === "sinal_ens_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                        setCampoOrdem("sinal_ens_ivas")
                                        }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.sinal_ens_atual"} />  
                                    </a>
                                </th>   
                            </tr>
                        </thead>
                        <tbody>
                            {tabelaOrdenada().map((value, index, array) => {
                                const isMesmoEstado = index < array.length - 1 && value["cod_uf"] === array[index + 1]["cod_uf"];
                            
                                return (
                                    <tr key={index}>
                                        <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeEstadoTabela(value["estado"]) }}>{ /*isMesmoEstado && index > 0 ? "" : */value["estado"]}</a></td>
                                        <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeMunicipioTabela(value["cod_ibge"]) }}>{value["municipio"]}</a></td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value[getNomeCampoIndice(indice)]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["dqi"]) === "Apto" ? t("dashboard.apto"): t("dashboard.nao_apto"))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["sinal_ens_ivas"]))}</td>
                                        

                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <Table id={"dtBasicExampleRankExport"} style={{display:"none"}} striped bordered hover variant={theme === "dark" ? "dark" : ""}>
                        <thead>
                            <tr>
                                <th><a href='#' style={{ color: campoOrdem === "estado" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "estado") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("estado")
                                }}>
                                <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> <Translator path={"dashboard.table.estado"} /></a>
                                </th>

                                <th><a href='#' style={{ color: campoOrdem === "municipio" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdem === "municipio") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                        setCampoOrdem("municipio")
                                    }}>
                                    <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> <Translator path={"dashboard.table.col1"} /></a>
                                </th>                    

                                <th>rank_den</th>
                                <th>rank_dsei</th>
                                <th>rank_ibp</th>
                                <th>rank_mob</th>
                                <th>rank_mob_den</th>
                                <th>rank_mob_den_dsei</th>
                                <th>rank_mob_den_ibp</th>
                                <th>rank_mob_den_ibp_dsei</th>
                                <th>rank_mob_dsei</th>
                                <th>rank_mob_ibp</th>
                                <th>rank_mob_ibp_dsei</th>

                                <th><a href='#' style={{ color: campoOrdem === "dqi" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "dqi") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("dqi")
                                }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> DQI </a></th>

                                <th>
                                    <a href='#' style={{ color: campoOrdem === "sinal_ens_ivas" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdem === "sinal_ens_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                        setCampoOrdem("sinal_ens_ivas")
                                        }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.sinal_ens_atual"} />  
                                    </a>
                                </th>  

                                
                                
                            </tr>
                        </thead>
                        <tbody>
                            {tabelaOrdenada().map((value, index, array) => {
                                const isMesmoEstado = index < array.length - 1 && value["cod_uf"] === array[index + 1]["cod_uf"];
                            
                                return (
                                    <tr key={index}>
                                        <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeEstadoTabela(value["estado"]) }}>{ /*isMesmoEstado && index > 0 ? "" : */value["estado"]}</a></td>
                                        <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeMunicipioTabela(value["cod_ibge"]) }}>{value["municipio"]}</a></td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_den"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_dsei"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_ibp"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_mob"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_mob_den"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_mob_den_dsei"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_mob_den_ibp"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_mob_den_ibp_dsei"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_mob_dsei"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_mob_ibp"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_mob_ibp_dsei"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["dqi"]) === "Apto" ?  t("dashboard.apto"): t("dashboard.nao_apto"))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["sinal_ens_ivas"]))}</td>
                                        

                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
                <div className='espacamento'></div>
                <div className='espacamento'></div>
                
                <div className={style.table}>
                    <div className={style.header_tabela}>
                    <div className={style.titulo_tabela}><h5><center>{!isAllSelect(estadoSelecionado) ? t("dashboard.table.titulo_orig_dest") : t("dashboard.table.titulo_orig_dest")}</center></h5></div>
                        <div className={style.export_buttons}>
                            <div className='divExportPlanilhaCSV'>
                                <img className="exportPlanilhaCSV" src='/imagens/csv_logo.gif' onClick={() => {exportToCSV("dtBasicExample", "")}}></img>
                                <div className="balaoDeTextoCsv noHover">Download CSV</div>
                            </div>
                            <div className={style.div_export_planilha_excel}>
                                <img className={style.export_planilha} src='/imagens/excel_logo.gif' onClick={() => {exportToExcel("dtBasicExample", "")}}></img>
                                <div className={style.balao_de_texto_excel}>Download XLSX</div>        
                            </div>
                        </div>
                    </div>
                        
                        {/*
                     
                    <div style={{backgroundColor:"red", maxHeight:"35px"}}>   
                        <div className={style.divExportPlanilhaCSV_}>
                            <img className="exportPlanilhaCSV" src='/imagens/csv_logo.gif' onClick={exportToCSV}></img>
                            <div className="balaoDeTextoCsv noHover">Download CSV</div>
                        </div>
                        <div className={style.divExportPlanilhaExcel_}>
                            <img className="exportPlanilha" src='/imagens/excel_logo.gif' onClick={exportToExcel}></img>
                            <div className="balaoDeTextoExcel noHover">Download XLSX</div>        
                        </div>
                     </div>   
                        */}
                    
                    <Table id={"dtBasicExample"} striped bordered hover variant={theme === "dark" ? "dark" : ""}>
                        <thead>
                            <tr>
                                <th colSpan={"2"}><center>{t("dashboard.origem")}</center></th>
                                <th colSpan={"2"}><center>{t("dashboard.destino")}</center></th>
                            </tr>
                            <tr>
                                <th><a href='#' style={{ color: campoOrdemOrigemDestino === "estado_origem" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdemOrigemDestino === "estado_origem") { setDecrescenteOrigemDestino(!decrescenteOrigemDestino); } else { setDecrescenteOrigemDestino(false); }
                                    setCampoOrdemOrigemDestino("estado_origem")
                                }}>
                                <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> <Translator path={"dashboard.table.estado"} /></a>
                                </th>

                                <th><a href='#' style={{ color: campoOrdemOrigemDestino === "municipio_origem" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdemOrigemDestino === "municipio_origem") { setDecrescenteOrigemDestino(!decrescenteOrigemDestino); } else { setDecrescenteOrigemDestino(false); }
                                        setCampoOrdemOrigemDestino("municipio_origem")
                                    }}>
                                    <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> <Translator path={"dashboard.table.col1"} /></a>
                                </th>

                                <th><a href='#' style={{ color: campoOrdemOrigemDestino === "estado_destino" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdemOrigemDestino === "estado_destino") { setDecrescenteOrigemDestino(!decrescenteOrigemDestino); } else { setDecrescenteOrigemDestino(false); }
                                    setCampoOrdemOrigemDestino("estado_destino")
                                }}>
                                <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> <Translator path={"dashboard.table.estado"} /></a>
                                </th>

                                <th><a href='#' style={{ color: campoOrdemOrigemDestino === "municipio_destino" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdemOrigemDestino === "municipio_destino") { setDecrescenteOrigemDestino(!decrescenteOrigemDestino); } else { setDecrescenteOrigemDestino(false); }
                                        setCampoOrdemOrigemDestino("municipio_destino")
                                    }}>
                                    <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> <Translator path={"dashboard.table.col1"} /></a>
                                </th> 
                                
                            </tr>
                        </thead>
                        <tbody>
                            {tabelaOrdenadaOrigemDestino().map((value, index, array) => {
                                const isMesmoEstado = index < array.length - 1 && value["cod_uf"] === array[index + 1]["cod_uf"];
                            
                                return (
                                    <tr key={index}>
                                        <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeEstadoTabela(value["estado_origem"]) }}>{ /*isMesmoEstado && index > 0 ? "" : */value["estado_origem"]}</a></td>
                                        <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeMunicipioTabela(value["cod_ibge_origem"]) }}>{value["municipio_origem"]}</a></td>
                                        <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeEstadoTabela(value["estado_destino"]) }}>{ /*isMesmoEstado && index > 0 ? "" : */value["estado_destino"]}</a></td>
                                        <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeMunicipioTabela(value["cod_ibge_destino"]) }}>{value["municipio_destino"]}</a></td>

                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    
                </div>
            </Row>
            <Footer date={dataCache}></Footer>
        </Container>
    )
}

export default Metagenomica