import style from './IBP.module.css'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Translator from '../I18n/Translator';
import { t, use } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { UsuarioContext } from '../../common/context/UsuarioContext';
import { TemaContext } from '../../common/context/TemaContext';
import { LinguagemContext } from '../../common/context/LinguagemContext';
import { Button, Col, Container, Nav, Row, Table } from 'react-bootstrap'
import { Box, Slider, TextField, Autocomplete, FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import L from 'leaflet';
import "./IBP.css";
import * as XLSX from 'xlsx';
import TabNav from '../TabNav';
import { set } from 'date-fns';

var posicao = localStorage.getItem('posicaoScroll');

function IBP() {
    let dataDados = localStorage.getItem('dataCacheFiles');

    const {theme} = useContext(TemaContext);
    const {language} = useContext(LinguagemContext);
    const {logado} = useContext(UsuarioContext);

    const [datas, setDatas] = useState([]);
    const [semanas, setSemanas] = useState([]);
    const [inicioSemanas, setInicioSemanas] = useState([]);
    const [fimSemanas, setFimSemanas] = useState([]);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const position = [-15.0, -54.27390005057997];
    const defaultZoom = 3.00;
    const zoomSnap_ = 0.25;
    const wheelPxPerZoomLevel_ = 120;

    const iniciou = useRef(false);

    var alterandoData = false;

    var weightNormal = theme === "dark" ? 0.6 : 0.6;
    var weightBrasil = 1.5;

    var weightSelecao = 3.5; 

    const transparencia = useRef(0.5);

    var opacidadeMunicipio = 1.0;

    var zoomPorClique = false;

    const transparenciaDefaultMapa = useMemo(() => {
        return "rgba(255, 255, 255, 0)";
    }, []);
    const cinzaDefaultMapaDark = useMemo(() => {
        return "#272727";
    }, []);
    const cinzaDefaultMapaBorda = useMemo(() => {
        return "#C1c3c3";//"rgba(221, 220, 223, 0.3)";
    }, []);


    const corMapa1 = useMemo(() => { 
        return "#000080"; 
    }, []); 
    const corMapa1Borda = useMemo(() => { 
        return "#000059";
    }, []);
    const  corMapa1BordaDark = useMemo(() => {  
        return "#4c4ccc";
    }, []);    
    
    const corMapa2 = useMemo(() => {  
        return "#0080ff"; 
    }, []); 
    const corMapa2Borda = useMemo(() => {  
        return "#0059b2";
    }, []);
    const  corMapa2BordaDark = useMemo(() => {  
        return "#4cccff";
    }, []);

    const corMapa3 = useMemo(() => {  
        return "#b9fd82"; 
    }, []); 
    const corMapa3Borda = useMemo(() => {  
        return "#56b256";
    }, []);
    const  corMapa3BordaDark = useMemo(() => {  
        return "#c8ffc8";
    }, []);

    const corMapa4 = useMemo(() => {  
        return "#ff9700"; 
    }, []); 
    const corMapa4Borda = useMemo(() => {  
        return "#b26a00";
    }, []);
    const  corMapa4BordaDark = useMemo(() => {  
        return "#ffe34c";
    }, []);

    const corMapa5 = useMemo(() => {  
        return "#a80202"; 
    }, []); 
    const corMapa5Borda = useMemo(() => {  
        return "#450000";
    }, []);
    const  corMapa5BordaDark = useMemo(() => {  
        return "#e05a5a";
    }, []);

    const municipiosFaixa1 = useRef([]);
    const municipiosFaixa2 = useRef([]);
    const municipiosFaixa3 = useRef([]);
    const municipiosFaixa4 = useRef([]);
    const municipiosFaixa5 = useRef([]);

    const contadorFaixa1 = useRef(0);
    const contadorFaixa2 = useRef(0);
    const contadorFaixa3 = useRef(0);
    const contadorFaixa4 = useRef(0);
    const contadorFaixa5 = useRef(0);
    const [atualizouContadores, setAtualizouContadores] = useState(false);

    const atendIvasFaixa1 = useRef(0);
    const atendIvasFaixa2 = useRef(0);
    const atendIvasFaixa3 = useRef(0);
    const atendIvasFaixa4 = useRef(0);
    const atendIvasFaixa5 = useRef(0);

    const atendTotFaixa1 = useRef(0);
    const atendTotFaixa2 = useRef(0);
    const atendTotFaixa3 = useRef(0);
    const atendTotFaixa4 = useRef(0);
    const atendTotFaixa5 = useRef(0);

    const data_colormap = useRef([]);
    var color_scale = null;
    let escala = "ºC";

    const legenda = [];
    var legendaSemana = [];
    const legend = useRef(null);

    const [dados_municipios_por_data, setDados_Municipios_Por_Data] = useState(null);

    const isAllSelect = (variavel) => {
        return variavel === "All" || variavel === "Todos";
    }

    //refs shapefiles
    const shapeFileBrasil = useRef(null);
    const shapeFileMunicipios = useRef(null);

    /*Variáveis Tabela*/
    const [campoOrdem, setCampoOrdem] = useState("classe ibp");
    const [decrescente, setDecrescente] = useState(false);

    function exportToExcel(nomeTabela, nomeArquivo) {
        const htmlTable = document.getElementById(nomeTabela);
        if(htmlTable === null)
            return
        const tableCopy = htmlTable.cloneNode(true);

        // Modifique a cópia da tabela para remover caracteres indesejados
        cleanTableData(tableCopy);

        let dataAtual = new Date();
        let dataSelecionada = dataAtual.toLocaleString('pt-BR', { timeZone: 'UTC' });
        //faça a saida de dataSelecionada para o formato desejado dd-mm-yyyy
        dataSelecionada = dataSelecionada.replace(/\//g, "-");
        dataSelecionada = dataSelecionada.substring(0, 10);

        var nomeArquivoLocal = dataSelecionada.replace(" ", "");
        nomeArquivoLocal = nomeArquivo.replace("-", "_");
        nomeArquivoLocal = nomeArquivo.replace("-", "_");
        nomeArquivoLocal = nomeArquivo.replace("00:00:00", "");

        const wb = XLSX.utils.table_to_book(tableCopy);
        XLSX.writeFile(wb, 'tabela_'+nomeArquivoLocal+'_'+nomeArquivo+'.xlsx');
    }

    function exportToCSV(nomeTabela, nomeArquivo) {
        const htmlTable = document.getElementById(nomeTabela);
        if(htmlTable === null)
            return;

        console.log(htmlTable);
        const rows = htmlTable.querySelectorAll('tr');

        let csv = '';
        for (const row of rows) {
            const cells = row.querySelectorAll('th,td');
            const rowData = Array.from(cells).map(cell => cell.textContent.replace('↑', ''));
            csv += rowData.join(',') + '\n';
        }

        let dataAtual = new Date();
        let dataSelecionada = dataAtual.toLocaleString('pt-BR', { timeZone: 'UTC' });
        //faça a saida de dataSelecionada para o formato desejado dd-mm-yyyy sem a hora
        dataSelecionada = dataSelecionada.replace(/\//g, "-");
        dataSelecionada = dataSelecionada.substring(0, 10);

        //remove a hora
        var nomeArquivoLocal = dataSelecionada.replace(" ", "");
        nomeArquivoLocal = nomeArquivoLocal.replace("-", "_");
        nomeArquivoLocal = nomeArquivoLocal.replace("-", "_");
        nomeArquivoLocal = nomeArquivoLocal.replace("00:00:00", "");

        const blob = new Blob([csv], { type: 'text/csv;charset=iso-8859-1;'});
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'tabela_'+nomeArquivoLocal+'_'+nomeArquivo+'.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    function cleanTableData (table) {
        // Obtém todas as células da tabela
        const cells = table.querySelectorAll('td');
    
        // Itera sobre cada célula e remove caracteres indesejados
        cells.forEach((cell) => {
          // Substitua 'caractere-indesejado' pelo caractere que você deseja remover
          const cleanedText = cell.textContent.replace(/↑/g, '');
    
          // Atualiza o conteúdo da célula com o texto limpo
          cell.textContent = cleanedText;
        });
    };

    const getNomeCidadeWhereCodIbge = (cod_ibge_local) => {
        let retorno = null;
        if(isNumeric(cod_ibge_local)){
            const keys = Object.keys(shapeFileMunicipios.current["_layers"]);
            if (keys.length > 0) {
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    const elementLoop = shapeFileMunicipios.current["_layers"][key];
                    if(elementLoop !== undefined){
                        let cod_temp = parseInt(elementLoop["feature"]["properties"]["CD_MUN"]);
                        if(elementLoop["feature"]["properties"]["CD_MUN"] === cod_ibge_local || cod_temp === cod_ibge_local){
                            retorno =  elementLoop["feature"]["properties"]["NM_MUN"];
                            break;
                        }
                    } else {
                        retorno = cod_ibge_local;
                    }
                }
            } 
        }
        else {
            retorno = cod_ibge_local;
        }
        return retorno;
    }

    const getCodIbgeShapefile = (shapefile, shape) => {
        let valor = shapefile["_layers"][shape]["feature"]["properties"]["CD_MUN"];
        if(typeof shapefile["_layers"][shape]["feature"]["properties"]["CD_MUN"] === "number"){
            valor = shapefile["_layers"][shape]["feature"]["properties"]["CD_MUN"].toString();
        }
        return (valor);
    }

    const isNumeric = (value) => {
        return /^\d+(?:\.\d+)?$/.test(value); 
    }

    var estados_labels = [
        {label:t("dashboard.todos")},
        {label:"Acre"},
        {label:"Alagoas"},
        {label:"Amapá"},
        {label:"Amazonas"},
        {label:"Bahia"},
        {label:"Ceará"},
        {label:"Distrito Federal"},
        {label:"Espírito Santo"},
        {label:"Goiás"},
        {label:"Maranhão"},
        {label:"Mato Grosso"},
        {label:"Mato Grosso do Sul"},
        {label:"Minas Gerais"},
        {label:"Pará"},
        {label:"Paraíba"},
        {label:"Paraná"},
        {label:"Pernambuco"},
        {label:"Piauí"},
        {label:"Rio de Janeiro"},
        {label:"Rio Grande do Norte"},
        {label:"Rio Grande do Sul"},
        {label:"Rondônia"},
        {label:"Roraima"},
        {label:"Santa Catarina"},        
        {label:"São Paulo"},
        {label:"Sergipe"},
        {label:"Tocantins"},
    ];

    var cod_estados = [
        {"UF":"AC",
        "COD": 12, "NOME":"Acre"},
        {"UF":"AL",
        "COD": 27, "NOME":"Alagoas"},
        {"UF":"AP",
        "COD": 16, "NOME":"Amapá"},
        {"UF":"AM",
        "COD": 13, "NOME":"Amazonas"},
        {"UF":"BA",
        "COD": 29, "NOME":"Bahia"},
        {"UF":"CE",
        "COD": 23, "NOME":"Ceará"},
        {"UF":"DF",
        "COD": 53, "NOME":"Distrito Federal"},
        {"UF":"ES",
        "COD": 32, "NOME":"Espírito Santo"},
        {"UF":"GO",
        "COD": 52, "NOME":"Goiás"},
        {"UF":"MA",
        "COD": 21, "NOME":"Maranhão"},
        {"UF":"MT",
        "COD": 51, "NOME":"Mato Grosso"},
        {"UF":"MS",
        "COD": 50, "NOME":"Mato Grosso do Sul"},
        {"UF":"MG",
        "COD": 31, "NOME":"Minas Gerais"},
        {"UF":"PA",
        "COD": 15, "NOME":"Pará"},
        {"UF":"PB",
        "COD": 25, "NOME":"Paraíba"},
        {"UF":"PR",
        "COD": 41, "NOME":"Paraná"},
        {"UF":"PE",
        "COD": 26, "NOME":"Pernambuco"},
        {"UF":"PI",
        "COD": 22, "NOME":"Piauí"},
        {"UF":"RJ",
        "COD": 33, "NOME":"Rio de Janeiro"},
        {"UF":"RN",
        "COD": 24, "NOME":"Rio Grande do Norte"},
        {"UF":"RS",
        "COD": 43, "NOME":"Rio Grande do Sul"},
        {"UF":"RO",
        "COD": 11,"NOME":"Rondônia"},
        {"UF":"RR",
        "COD": 14,"NOME":"Roraima"},
        {"UF":"SC",
        "COD": 42,"NOME":"Santa Catarina"},        
        {"UF":"SP",
        "COD": 35,"NOME":"São Paulo"},
        {"UF":"SE",
        "COD": 28,"NOME":"Sergipe"},
        {"UF":"TO",
        "COD": 17,"NOME":"Tocantins"},
    ];

    var geojsonBrasil = (require('./Dados/shapefile/BR_UF_2022.json'));
    var geojsonMunicipios = (require('./Dados/shapefile/BR_Municipios_2022.json'));

    posicao = localStorage.getItem('posicaoScroll');
    var nomes_estados = (require('./Dados/estados.json'));

    //refs mapa
    const layerTile = useRef(null);
    const layerControl = useRef(null);
    let map = useRef(null);

    function onEachFeatureEstado(feature, layer) {
        layer.on({
            mouseover: function (e) {
                var layer = e.target;
                // Adiciona um popup com o nome do município
                if (feature.properties && feature.properties.NM_MUN) {
                    layer.bindPopup(feature.properties.NM_MUN + " - " + feature.properties.SIGLA_UF, {
                        className: "popup-estilo"
                    }).openPopup();
                }
            },
            mouseout: function (e) {
                var layer = e.target;
                //shapeFileMunicipios.resetStyle(layer);
                layer.closePopup();
            },
            click: () => {
                if(cod_ibge === getCodIbgeLayerShapefile(layer)){
                    map.current.fitBounds(layer.getBounds().pad(1));
                    return;
                }

                setLoading(true);
   
                //resetShapeFile(estadoSelecionado, municipioSelecionado, true);

                let siglaEstadoSelecionada = feature["properties"]["SIGLA_UF"];

                let codEstadoSelecionado = getCodEstadoBySigla(siglaEstadoSelecionada);

                let nomeEstadoSelecionado = getNomeEstadoByCodIbge(codEstadoSelecionado);

                if(estadoSelecionado !== nomeEstadoSelecionado){
                    carregaMunicipiosPorEstado(siglaEstadoSelecionada);

                    setEstadoSelecionado(nomeEstadoSelecionado);
                }

                setMunicipioSelecionado(layer['feature']['properties']['NM_MUN']/*getNomeCidadeWhereCodIbge(getCodIbgeLayerShapefile(layer))*/);
                setCod_Ibge(getCodIbgeLayerShapefile(layer));

                layer.setStyle({ weight: weightSelecao, color: "black", fillOpacity: opacidadeMunicipio  });
                zoomPorClique = true;
                map.current.fitBounds(layer.getBounds().pad(1));

                setLoading(false);
            },
            
        });
    }

    function getCodIbgeLayerShapefile(layer) {
        return (layer["feature"]["properties"]["CD_MUN"]);
    }

    const getCodEstadoBySigla = (sigla) => {
        for(let i = 0; i < cod_estados.length; i++){
            if(cod_estados[i]["UF"] === sigla){
                return cod_estados[i]["COD"];
            }
        }
    }

    const fixaCasasDecimais = (valor, casasDecimais) => {
        /*if(valor === valorNil) 
            return valorNil;*/

        /*
        if (typeof valor === 'string') {
            valor = parseFloat(valor.replace(',', '.'));
        }
        const fator = Math.pow(10, casasDecimais);
        const valorArredondado = Math.round(valor * fator) / fator;
        return valorArredondado.toFixed(casasDecimais);
        */
        
        return valor.toFixed(casasDecimais);
        return valor;
    }

    const getDadosPorData = (dt, linguagem, pintaCirculo) => {
        var path_dir = "semanal";
        if (dt !== undefined && dt !== null && dt !== 0) {            
            fetch('Dados/dados_municipios/' + path_dir + '/datas/dados_' + dt.replace(" 00:00:00", "") + '.json?' + dataDados
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then(function (response) {
                    //Limpa pontos anteriores
                    return response.json();
                })
                .then(function (myJson) {
                   /* contadorFaixa1.current = 0;
                    contadorFaixa2.current = 0;
                    contadorFaixa3.current = 0;
                    contadorFaixa4.current = 0;
                    contadorFaixa5.current = 0;

                    atendIvasFaixa1.current = 0;
                    atendIvasFaixa2.current = 0;
                    atendIvasFaixa3.current = 0;
                    atendIvasFaixa4.current = 0;
                    atendIvasFaixa5.current = 0;

                    atendTotFaixa1.current = 0;
                    atendTotFaixa2.current = 0;
                    atendTotFaixa3.current = 0;
                    atendTotFaixa4.current = 0;
                    atendTotFaixa5.current = 0; */


                    setDados_Municipios_Por_Data(myJson);
                    //console.log(myJson);

                    /*for(let i = 0; i < municipiosFaixa1.current.length; i++){
                        let cod_ibge = municipiosFaixa1.current[i];
                        //let municipio = getNomeCidadeWhereCodIbge(cod_ibge);
                        let valor = 0;
                        for(let j = 0; j < myJson["municipios"].length; j++){
                            //console.log(myJson["municipios"][j]["cod_ibge"], cod_ibge);
                            if(myJson["municipios"][j]["cod_ibge"] === cod_ibge && myJson["municipios"][j]["dqi"] === "Apto"){
                                atendIvasFaixa1.current += myJson["municipios"][j]["total_gripal"];
                                atendTotFaixa1.current += myJson["municipios"][j]["total_geral"];
                                valor = myJson["municipios"][j]["sinal_ens_ivas"];
                                if(valor === 1)
                                    contadorFaixa1.current++;
                                break;
                            }
                        }  
                    }

                    for(let i = 0; i < municipiosFaixa2.current.length; i++){
                        let cod_ibge = municipiosFaixa2.current[i];
                        //let municipio = getNomeCidadeWhereCodIbge(cod_ibge);
                        let valor = 0;
                        
                        for(let j = 0; j < myJson["municipios"].length; j++){
                            if(myJson["municipios"][j]["cod_ibge"] === cod_ibge && myJson["municipios"][j]["dqi"] === "Apto"){
                                atendIvasFaixa2.current += myJson["municipios"][j]["total_gripal"];
                                atendTotFaixa2.current += myJson["municipios"][j]["total_geral"];
                                valor = myJson["municipios"][j]["sinal_ens_ivas"];
                                if(valor === 1)
                                    contadorFaixa2.current++;
                                break;
                            }
                        }  
                    }

                    for(let i = 0; i < municipiosFaixa3.current.length; i++){
                        let cod_ibge = municipiosFaixa3.current[i];
                        //let municipio = getNomeCidadeWhereCodIbge(cod_ibge);
                        let valor = 0;
                        for(let j = 0; j < myJson["municipios"].length; j++){
                            if(myJson["municipios"][j]["cod_ibge"] === cod_ibge && myJson["municipios"][j]["dqi"] === "Apto"){
                                atendIvasFaixa3.current += myJson["municipios"][j]["total_gripal"];
                                atendTotFaixa3.current += myJson["municipios"][j]["total_geral"];
                                valor = myJson["municipios"][j]["sinal_ens_ivas"];
                                if(valor === 1)
                                    contadorFaixa3.current++;
                                break;
                            }
                        }  
                    }

                    for(let i = 0; i < municipiosFaixa4.current.length; i++){
                        let cod_ibge = municipiosFaixa4.current[i];
                        //let municipio = getNomeCidadeWhereCodIbge(cod_ibge);
                        let valor = 0;
                        for(let j = 0; j < myJson["municipios"].length; j++){
                            if(myJson["municipios"][j]["cod_ibge"] === cod_ibge && myJson["municipios"][j]["dqi"] === "Apto"){
                                atendIvasFaixa4.current += myJson["municipios"][j]["total_gripal"];
                                atendTotFaixa4.current += myJson["municipios"][j]["total_geral"];
                                valor = myJson["municipios"][j]["sinal_ens_ivas"];
                                if(valor === 1)
                                    contadorFaixa4.current++;
                                break;
                            }
                        }  
                    }

                    for(let i = 0; i < municipiosFaixa5.current.length; i++){
                        let cod_ibge = municipiosFaixa5.current[i];
                        //let municipio = getNomeCidadeWhereCodIbge(cod_ibge);
                        let valor = 0;
                        for(let j = 0; j < myJson["municipios"].length; j++){
                            if(myJson["municipios"][j]["cod_ibge"] === cod_ibge && myJson["municipios"][j]["dqi"] === "Apto"){
                                atendIvasFaixa5.current += myJson["municipios"][j]["total_gripal"];
                                atendTotFaixa5.current += myJson["municipios"][j]["total_geral"];
                                valor = myJson["municipios"][j]["sinal_ens_ivas"];
                                if(valor === 1)
                                    contadorFaixa5.current++;
                                break;
                            }
                        }  
                    }

                    console.log("faixa      -       sinais         -   atend ivas        -   atend tot");
                    console.log(dt + " faixa 1:" + contadorFaixa1.current + " - " + atendIvasFaixa1.current + " - " + atendTotFaixa1.current);
                    console.log(dt + " faixa 2:" + contadorFaixa2.current + " - " + atendIvasFaixa2.current + " - " + atendTotFaixa2.current);
                    console.log(dt + " faixa 3:" + contadorFaixa3.current   + " - " + atendIvasFaixa3.current + " - " + atendTotFaixa3.current);
                    console.log(dt + " faixa 4:" + contadorFaixa4.current   + " - " + atendIvasFaixa4.current + " - " + atendTotFaixa4.current);
                    console.log(dt + " faixa 5:" + contadorFaixa5.current   + " - " + atendIvasFaixa5.current + " - " + atendTotFaixa5.current);

                    criarLegenda(0, 1, "ens", linguagem);*/

                    console.log(contadorFaixa1.current, contadorFaixa2.current, contadorFaixa3.current, contadorFaixa4.current, contadorFaixa5.current);

                    criarLegenda(0, 1, "ens", linguagem);
                });
        }
    }

    function criarLegenda(min, max, variavel, linguagem) {

        if (map.current === null)
            return;

        fetch('Dados/min_max_valor_ibp.json?'  + dataDados, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            let valor1Min = myJson[0]["min_valor_ibp"];
            let valor1Max = myJson[0]["max_valor_ibp"];

            let valor2Min = myJson[1]["min_valor_ibp"];
            let valor2Max = myJson[1]["max_valor_ibp"];

            let valor3Min = myJson[2]["min_valor_ibp"];
            let valor3Max = myJson[2]["max_valor_ibp"];

            let valor4Min = myJson[3]["min_valor_ibp"];
            let valor4Max = myJson[3]["max_valor_ibp"];

            let valor5Min = myJson[4]["min_valor_ibp"];
            let valor5Max = myJson[4]["max_valor_ibp"];

            if (legend.current !== null) {
                map.current.removeControl(legend.current );
                legend.current  = null;
            }
    
            legend.current  = L.control({ position: "bottomleft" });
    
            legend.current.onAdd = function (map) {
                var div = null;
                div = L.DomUtil.create("div", "legend_");
                //div.innerHTML += '<table><tr><th  bgcolor='+corMapa1+'>&nbsp;&nbsp;&nbsp;</th><th  bgcolor='+corMapa2+'>&nbsp;&nbsp;&nbsp;</th><th bgcolor='+corMapa3+'>&nbsp;&nbsp;&nbsp;</th></tr><tr><td style="text-align:left">0</td><td style="text-align:center">0.5</td><td style="text-align:right">1</td></tr>';
                //div.innerHTML += '<table><tr><th  bgcolor='+corMapa1+'>&nbsp;&nbsp;&nbsp;</th><th bgcolor='+corMapa3+'>&nbsp;&nbsp;&nbsp;</th></tr><tr><td style="text-align:left">0</td><td style="text-align:right">1</td></tr>';
                
                //div.innerHTML += '</table>';
    
                // Adicionando linhas para cada cor e descrição 
                var descricaoIndice = "IBP";
    
                /*let corNaoApto = theme === "dark" ? corMapaNaoAptoDark : corMapaNaoApto;
                let corNoData = theme === "dark" ? corMapaNoDataDark : corMapaNoData;*/

                let esp = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                let esp2 = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
    
                div.innerHTML += '<center><h6 class="titulo-legend">' + t("dashboard.ibp") + '</h6></center>';
                div.innerHTML += '<center><h6 class="titulo-legend">' + t("dashboard.faixa") + esp2 + esp2 + esp2 + t("dashboard.sinais_faixa") + '</h6></center>';
                div.innerHTML += '<div class="legend-item"><div class="square" style="background-color: '+corMapa1+'"></div>'+fixaCasasDecimais(valor1Min, 3) +" à " + fixaCasasDecimais(valor1Max, 3) + esp + "&nbsp;&nbsp;&nbsp;" +  contadorFaixa1.current + '</div>';
                div.innerHTML += '<div class="legend-item"><div class="square" style="background-color: '+corMapa2+'"></div>'+fixaCasasDecimais(valor2Min, 3) +" à " + fixaCasasDecimais(valor2Max, 3) + esp + "&nbsp;&nbsp;&nbsp;" +  contadorFaixa2.current +'</div>';
                div.innerHTML += '<div class="legend-item"><div class="square" style="background-color: '+corMapa3+'"></div>'+fixaCasasDecimais(valor3Min, 3) +" à " + fixaCasasDecimais(valor3Max, 3) + esp + "&nbsp;&nbsp;&nbsp;&nbsp;" +  contadorFaixa3.current +'</div>';
                div.innerHTML += '<div class="legend-item"><div class="square" style="background-color: '+corMapa4+'"></div>'+fixaCasasDecimais(valor4Min, 3) +" à " + fixaCasasDecimais(valor4Max, 3) + esp + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" +  contadorFaixa4.current +'</div>';
                div.innerHTML += '<div class="legend-item"><div class="square" style="background-color: '+corMapa5+'"></div>'+fixaCasasDecimais(valor5Min, 3) +" à " + fixaCasasDecimais(valor5Max, 3) + esp + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" +  contadorFaixa5.current +'</div>';
                                
                /*div.innerHTML += '<div class="legend-item"><div class="square" style="background-color: '+ corNaoApto +'"></div>'+t("dashboard.nao_apto")+'</div>';
                div.innerHTML += '<div class="legend-item"><div class="square" style="background-color: '+ corNoData +'"></div>'+t("dashboard.no_data")+'</div>';*/
    
                div.innerHTML += '</div>';
                return div;
            };            
            
    
            legend.current.addTo(map.current);
        });
    }

    const getNomeEstadoByCodIbge = (cod_ibge_local) => {
        let retorno = null;
        if(isNumeric(cod_ibge_local) && shapeFileBrasil.current !== null && shapeFileBrasil.current["_layers"] !== null){
            const keys = Object.keys(shapeFileBrasil.current["_layers"]);
            if (keys.length > 0) {
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    const element = shapeFileBrasil.current["_layers"][key];
                    if(element !== undefined){
                        let cod_temp = parseInt(element["feature"]["properties"]["CD_UF"]);
                        if(element["feature"]["properties"]["CD_UF"] === cod_ibge_local || cod_temp === cod_ibge_local){
                            retorno =  element["feature"]["properties"]["NM_UF"];
                            break;
                        } 
                    } else {
                        retorno = cod_ibge_local;
                    }
                }
            }  
        }
        else {
            retorno = cod_ibge_local;
        }
        return retorno;
    }

    function carregaMunicipiosPorEstado(siglaEstado){
        if(!loading){
            setLoading(true);
        }

        if(siglaEstado !== ''){
            fetch('Dados/lista_municipios/lista_municipios_'+siglaEstado+'.json'
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
            .then(function (response) {
                //Limpa pontos anteriores
                return response.json();
            })
            .then(function (myJson) {
                let listaTemp = [];
                let cidades_labels_tmp = [];
                for(let i = 0; i < myJson.length; i++){
                    listaTemp.push({
                        "cod_ibge": myJson[i]["COD_IBGE"],
                        "municipio": myJson[i]["MUNICIPIO"],
                        "latitude": myJson[i]["Y"],
                        "longitude": myJson[i]["X"]
                    });

                    cidades_labels_tmp.push({cod_ibge: myJson[i]["COD_IBGE"],  label: myJson[i]["MUNICIPIO"]});
                }

                cidades_labels_tmp.sort((a, b) => a.label.localeCompare(b.label));

                cidades_labels_tmp.unshift({cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")});//todos

                setCidades_Labels(cidades_labels_tmp);

                listaTemp.sort((a, b) => a["municipio"].localeCompare(b["municipio"]));
                
                listaTemp.unshift({
                    "cod_ibge": 0,
                    "municipio": t("dashboard.todos")
                });

                setListaMunicipios(listaTemp); 

                //getDadosPorData(datas[idxDataSelecionada], language, true);
            });
        }
    }
    
    function getCidades(somenteNomes) {
        var nomes_municipios = [];

        if(somenteNomes){
            nomes_municipios.unshift(
                t("dashboard.todos")
            );
            nomes_municipios.sort((a, b) => a.localeCompare(b));
        } else {
            nomes_municipios.unshift({
                "cod_ibge": 0,
                "municipio": t("dashboard.todos")
            });
            nomes_municipios.sort((a, b) => a["municipio"].localeCompare(b["municipio"]));
        }

        return nomes_municipios;
    }

    const getEstados = () => { 
        let nomes_estados_tmp = [];
        
        for (let i=0; i < nomes_estados.length; i++){
            nomes_estados_tmp.push(nomes_estados[i]);
        }

        nomes_estados_tmp.unshift({
            "UF": "All",
            "ESTADO": t("dashboard.todos")
        });         
        
        return nomes_estados_tmp;
    }

    const plotaMapa = (municipios, nomeFuncao) => {
        municipiosFaixa1.current = [];
        municipiosFaixa2.current = [];
        municipiosFaixa3.current = [];
        municipiosFaixa4.current = [];
        municipiosFaixa5.current = [];

        Object.keys(shapeFileMunicipios.current["_layers"]).forEach((shape) => {
            var cod_ibge_tmp = getCodIbgeShapefile(shapeFileMunicipios.current, shape);
            let transparenciaFill = theme === "dark" ? "#8d8d8f" : "#e5e5e6"/*"rgba(255,255,255,0)"*/;
            let cod_ibge_selecionado = getCodIbgeCidadeWhereNome(municipioSelecionado);
            for (let x = 0; x < municipios.length; x++) {
                var weight = weightNormal;
                if (cod_ibge_tmp === municipios[x]["cod_mun"].toString()) {
                    
                    var cor = transparenciaDefaultMapa;
                    var corContorno = theme === "dark" ? cinzaDefaultMapaDark : cinzaDefaultMapaBorda;

                    weight = weightNormal;
                    if(municipios[x]["quintil_ibp"] === "No data" || municipios[x]["quintil_ibp"] === null){
                        cor = transparenciaDefaultMapa;
                        corContorno = corContorno;
                    } else
                    if(municipios[x]["quintil_ibp"] === 1.0){
                        cor = corMapa1;
                        corContorno = theme === "dark" ? corMapa1BordaDark : corMapa1Borda;
                        municipiosFaixa1.current.push(municipios[x]["cod_mun"]);
                    } else if(municipios[x]["quintil_ibp"] === 2.0){
                        cor = corMapa2;
                        corContorno = theme === "dark" ? corMapa2BordaDark : corMapa2Borda;
                        municipiosFaixa2.current.push(municipios[x]["cod_mun"]);
                    } else if(municipios[x]["quintil_ibp"] === 3.0){
                        cor = corMapa3;
                        corContorno = theme === "dark" ? corMapa3BordaDark : corMapa3Borda;  
                        municipiosFaixa3.current.push(municipios[x]["cod_mun"]);
                    } else if(municipios[x]["quintil_ibp"] === 4.0){
                        cor = corMapa4;
                        corContorno = theme === "dark" ? corMapa4BordaDark : corMapa4Borda; 
                        municipiosFaixa4.current.push(municipios[x]["cod_mun"]); 
                    } else if(municipios[x]["quintil_ibp"] === 5.0){
                        cor = corMapa5;
                        corContorno = theme === "dark" ? corMapa5BordaDark : corMapa5Borda;
                        municipiosFaixa5.current.push(municipios[x]["cod_mun"]);  
                    }

                    if (cod_ibge_selecionado === shapeFileMunicipios.current["_layers"][shape]["feature"]["properties"]["CD_MUN"]) {
                        corContorno = /*theme === "dark" ? cinzaDefaultMapaDark : cinzaDefaultMapaBorda*/"black";
                        weight = weightSelecao;                           
                    }
    
                    if(corContorno === undefined){
                        corContorno = cor;
                    }
    
                    shapeFileMunicipios.current["_layers"][shape].setStyle({ weight: weight, color: corContorno, fillColor: cor, fillOpacity: opacidadeMunicipio});
    
                    break;  
                    
                }
            }                            
        });   

        /*
        console.log(municipiosFaixa1.current);
        console.log(municipiosFaixa2.current);
        console.log(municipiosFaixa3.current);
        console.log(municipiosFaixa4.current);
        console.log(municipiosFaixa5.current); */

        

        criarLegenda(0, 1, "", language);
        setLoading(false); 
    }

    const [loading, setLoading] = useState(true);
    const [cod_ibge, setCod_Ibge] = useState(t("dashboard.todos"));
    const [municipioSelecionado, setMunicipioSelecionado] = useState(t("dashboard.todos"));
    const [estadoSelecionado, setEstadoSelecionado] = useState(t("dashboard.todos"));
    const [listaMunicipios, setListaMunicipios] = useState(getCidades());
    const [listaEstados, setListaEstados] = useState(getEstados());
    const [cidades_labels, setCidades_Labels] = useState([{cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")}]);
    const [mapa_cor_value, setMapa_Cor_Value] = useState("jet");

    const [dados_Ivas_Pais, setDados_Ivas_Pais] = useState(null);

    const [dados_Municipio, setDados_Municipio] = useState(null);
    const [dados_Classes, setDados_Classes] = useState(null);

    const [link_zika, setLink_Zika] = useState("https://aesoparbov.outerlamce.com/");
    var link_gripe = "#";
    let dengue = true;

    var defaultProps = {
        options: estados_labels,
        getOptionLabel: (option) => option.label,
    };

    const defaultPropsMunicipio = {
        options: cidades_labels,
        getOptionLabel: (option) => option.label,
    };


    const handleChangeMunicipioNome = (nome_municipio) => {
        if (map.current !== null)
            map.current.closePopup();

        if(isAllSelect(nome_municipio)){
            Object.keys(shapeFileMunicipios.current["_layers"]).forEach((shape) => {
                let corBordaAnt = shapeFileMunicipios.current["_layers"][shape]["options"]["color"];
                if(corBordaAnt === "#dddcdf"){
                    corBordaAnt = "black"; 
                }
                shapeFileMunicipios.current["_layers"][shape].setStyle({ weight: weightNormal, color: corBordaAnt, fillOpacity: 1.0 });
            });

            setCod_Ibge(nome_municipio);
            setMunicipioSelecionado(getNomeCidadeWhereCodIbge(nome_municipio));
        } else {
            setCod_Ibge(nome_municipio);
            setMunicipioSelecionado(getNomeCidadeWhereCodIbge(nome_municipio));
            
            const keys = Object.keys(shapeFileMunicipios.current["_layers"]);
            if (keys.length > 0) {
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    const elementLoop = shapeFileMunicipios.current["_layers"][key];
                    if (nome_municipio === getCodIbgeShapefile(shapeFileMunicipios.current, key)) {
                        map.current.fitBounds(elementLoop.getBounds().pad(1));
                        break;
                    }
                }
            } 
        }
    };

    const [listaColorMap, setListaColorMap] = useState([
        {"value": "gray", "nome":  "Gray"},
        {"value": "jet", "nome": "Jet"},
        {"value": "rainbow", "nome": "Rainbow"},
        {"value": "gist_rainbow", "nome": "Gist Rainbow"},
        {"value": "hot", "nome": "Hot"},
        {"value": "brg", "nome": "brg"},
        {"value": "seismic", "nome": "seismic"},
        {"value": "cyan_light_to_blue_dark", "nome": "Cyan Light to Blue Dark"},
    ]);

    const valorNil = "nil";

    useEffect(() => {
        if (shapeFileBrasil.current === null) {
            shapeFileBrasil.current = L.geoJSON(geojsonBrasil, {
                interactive: false,
                style: function (feature) {
                    return { color: "black", fillColor: transparenciaDefaultMapa, stroke: true, weight: weightNormal, fillOpacity: transparencia.current };
                },
                /*onEachFeature: onEachFeatureBrasil,*/
            });
        }

        if (shapeFileMunicipios.current === null) {
            shapeFileMunicipios.current = L.geoJSON(geojsonMunicipios, {
                style: function (feature) {
                    return { color: "gray", fillColor: transparenciaDefaultMapa, stroke: true, weight: weightNormal, fillOpacity: opacidadeMunicipio };
                },
                onEachFeature: onEachFeatureEstado,
            });
        }

        fetch('Dados/dados_pais/dados_brasil.json')
        .then(response => response.json())
        .then(data => {
            setDados_Ivas_Pais(data); // Atribui os dados JSON à variável global
        })
        .catch(error => {
            console.error('Erro ao carregar o arquivo JSON:', error);
        });

        fetch('Dados/IBP.json?' + dataDados, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            setDados_Municipio(myJson);
            console.log(myJson);
            
            /*
            for(let i = 0; i < myJson.length; i++){
                switch(myJson[i]["quantil_ibp"]){
                    case 1:
                        if(valor1Max === 9999 && valor1Min === 9999){
                            setValor1Min(myJson[i]["valor_ibp"]);
                            setValor1Max(myJson[i]["valor_ibp"]);
                        } else {
                            if(myJson[i]["valor_ibp"] < valor1Min){
                                setValor1Min(myJson[i]["valor_ibp"]);
                            }
                            if(myJson[i]["valor_ibp"] > valor1Max){
                                setValor1Max(myJson[i]["valor_ibp"]);
                            }
                        }
                        break;
                    case 2:
                        if(valor2Max === 9999 && valor2Min === 9999){
                            setValor2Min(myJson[i]["valor_ibp"]);
                            setValor2Max(myJson[i]["valor_ibp"]);
                        } else {
                            if(myJson[i]["valor_ibp"] < valor2Min){
                                setValor2Min(myJson[i]["valor_ibp"]);
                            }
                            if(myJson[i]["valor_ibp"] > valor2Max){
                                setValor2Max(myJson[i]["valor_ibp"]);
                            }
                        }
                        break;
                    case 3:
                        if(valor3Max === 9999 && valor3Min === 9999){
                            setValor3Min(myJson[i]["valor_ibp"]);
                            setValor3Max(myJson[i]["valor_ibp"]);
                        } else {
                            if(myJson[i]["valor_ibp"] < valor3Min){
                                setValor3Min(myJson[i]["valor_ibp"]);
                            }
                            if(myJson[i]["valor_ibp"] > valor3Max){
                                setValor3Max(myJson[i]["valor_ibp"]);
                            }
                        }
                        break;
                    case 4:
                        if(valor4Max === 9999 && valor4Min === 9999){
                            setValor4Min(myJson[i]["valor_ibp"]);
                            setValor4Max(myJson[i]["valor_ibp"]);
                        } else {
                            if(myJson[i]["valor_ibp"] < valor4Min){
                                setValor4Min(myJson[i]["valor_ibp"]);
                            }
                            if(myJson[i]["valor_ibp"] > valor4Max){
                                setValor4Max(myJson[i]["valor_ibp"]);
                            }
                        }
                        break;
                    case 5:
                        if(valor5Max === 9999 && valor5Min === 9999){
                            setValor5Min(myJson[i]["valor_ibp"]);
                            setValor5Max(myJson[i]["valor_ibp"]);
                        } else {
                            if(myJson[i]["valor_ibp"] < valor5Min){
                                setValor5Min(myJson[i]["valor_ibp"]);
                            }
                            if(myJson[i]["valor_ibp"] > valor5Max){
                                setValor5Max(myJson[i]["valor_ibp"]);
                            }
                        }
                        break;
                }
            }*/

            plotaMapa(myJson, "useEffect 1");
            setLoading(false);
        });

        
        iniciou.current = true;
        localStorage.setItem('posicaoScroll', JSON.stringify(0));

        var dominio = window.location.hostname;

        if(dominio.includes("arbov")){
            dengue = false;
        } else {
            dengue = true;
        }

        if(dominio.includes("aesopdev")){
            setLink_Zika("https://aesoparbovdev.outerlamce.com/");
        } else if(dominio.includes("testeaesop.")) {
            setLink_Zika("https://testeaesoparbov.outerlamce.com/");
        } else {
            setLink_Zika("https://aesoparbov.outerlamce.com/");
        }

        if(dominio.includes("aesoparbovdev")){
            link_gripe = "https://aesopdev.outerlamce.com/";
        } else if(dominio.includes("teste")) {
            link_gripe = "https://testeaesop.outerlamce.com/";
        } else {
            link_gripe = "https://aesop.outerlamce.com/";
        }

    }, []);

    useEffect(() => {
        if(dados_Ivas_Pais){
            let datasTemp = [];
            let semanasTemp = [];
            let inicioSemanasTemp = [];
            let fimSemanasTemp = [];

            dados_Ivas_Pais.forEach(function (atendimento) {
                datasTemp.push(atendimento["data"]);
                semanasTemp.push((atendimento["semana"] < 10 ? "0" : "") + atendimento["semana"] + "-" + atendimento["data"].substring(0, 4));
                inicioSemanasTemp.push(atendimento["data_inicial_semana"]);
                fimSemanasTemp.push(atendimento["data_final_semana"]);
            });
            
            setDatas(datasTemp);
            setSemanas(semanasTemp);
            setInicioSemanas(inicioSemanasTemp);
            setFimSemanas(fimSemanasTemp);

            getDadosPorData(datasTemp[datasTemp.length-1], language, true);
        }
    }, [dados_Ivas_Pais]);

    useEffect(() => {
        if(!iniciou.current)
            return;

        var url = theme === "dark" ? "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}" : "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}";

        const { innerWidth: width, innerHeight: height } = window;

        layerTile.current = L.tileLayer(url, {
            zoomControl: true,
            zoomSnap: zoomSnap_,
            zoomDelta: zoomSnap_,
            wheelPxPerZoomLevel: wheelPxPerZoomLevel_,
            defaultZoom: defaultZoom,
            maxZoom: 18,
            id: 'mapbox.streets'
        });

        if (map.current === null) {
            map.current = L.map("map", {
                minZoom: 4,
                maxZoom: 10,
                layers: [layerTile.current]
            });
            
            //shapeFileBrasilExcesso.current.addTo(map.current);
        } else {
            map.current.invalidateSize();
        }

        const defaultCenter = position;

        if(width < 1300)
            map.current.setView([-13.338793, -45.206666], defaultZoom);
        else 
            map.current.setView(defaultCenter, defaultZoom);

        if(shapeFileMunicipios.current === null){
            shapeFileMunicipios.current = L.tileLayer('');
        }

        shapeFileMunicipios.current.addTo(map.current);
        shapeFileBrasil.current.addTo(map.current);

        const lineCoordinates = [
            [-22.890090, -42.029405], // Latitude e longitude do ponto inicial
            [-12.924167, -38.440701],  // Latitude e longitude do ponto final
          ];

        const lineCoordinates2 = [
            [-23.62340166156419, -46.65858576087318], // Latitude e longitude do ponto inicial
            [-12.988999467983497, -38.50386516595166],  // Latitude e longitude do ponto final
          ];

        if (map.current) {
            // Adiciona um ouvinte de evento para o evento de zoomend
            const onZoomEnd = (event) => {
              // Obtém o nível de zoom atual
              const currentZoom = event.target.getZoom();

              if(theme === "dark"){
                if(currentZoom <=  5){
                    weightNormal = 0.5;
                }  else {
                    weightNormal = 1;
                }
              }
              //console.log(currentZoom);
      
            };
      
            // Adiciona o ouvinte de evento ao mapa para o evento de zoomend
            map.current.on('zoomend', onZoomEnd);
        } 

        let myGeoRaster = "Dados/geotif/Bio01_BR.tif";

        const parseGeoraster = require("georaster");


        var noDataValue = 0;
        
    }, [map]);

    useEffect(() => {
        if(map.current === null){
            console.log("Não vai carregar");
        }

        console.log("Vai carregar");
        
        criarLegenda(0, 1, "", language);
    }, [atualizouContadores]);

    useEffect(() => {
        if ((map.current === null))
            return;

        if (layerTile.current !== null) {
            map.current.removeLayer(layerTile.current);
        }

        //setCookie("themeCookieDashboard", theme, 0.5);

        var url = theme === "dark" ? "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}" : "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}";

        const defaultCenter = position;

        layerTile.current = L.tileLayer(url, {
            zoomControl: true,
            zoomSnap: zoomSnap_,
            zoomDelta: zoomSnap_,
            wheelPxPerZoomLevel: wheelPxPerZoomLevel_,
            defaultZoom: defaultZoom,
            maxZoom: 18,
            id: 'mapbox.streets'
        });
        /*map.current.setView(defaultCenter, defaultZoom);*/
        layerTile.current.addTo(map.current);

        //corSelecao = (theme === "dark" ? "white" : "#757575");

        //resetShapeFile(estadoSelecionado, municipioSelecionado, false);

        if (posicao) {
            setTimeout(function () {
                window.scrollTo(0, posicao);
            }, 1);
        }

        if(dados_Municipio)
            plotaMapa(dados_Municipio, "Theme");

        //criarLegenda(0,0,"", language);
    }, [theme]);

    useEffect(() => {
        if(isAllSelect(estadoSelecionado)){
            setEstadoSelecionado(t("dashboard.todos"));
        }

        estados_labels[0] = {label:t("dashboard.todos")};

        if(isAllSelect(municipioSelecionado)){
            setListaMunicipios(getCidades());
            setMunicipioSelecionado(t("dashboard.todos"));
            let cidades_labels_tmp = [{cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")}];
            setCidades_Labels(cidades_labels_tmp);
        }

        setListaEstados(getEstados());

        criarLegenda(0,0,"", language);
    }, [language]);

    useEffect(() => {
        let siglaEstado = '';
        let codigo_estado = 0;

        if(shapeFileBrasil.current !== null){
            Object.keys(shapeFileBrasil.current["_layers"]).forEach((shape) => {
                if(isAllSelect(estadoSelecionado)){
                    shapeFileBrasil.current["_layers"][shape].setStyle({ weight: weightNormal, color: /*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaBrasilWhite*/transparenciaDefaultMapa, fillColor: /*'#E63100'*//*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaWhite*/ transparenciaDefaultMapa, fillOpacity: 0.5 });
                } else
                if(shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["NM_UF"] === estadoSelecionado){ 
                    siglaEstado = shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["SIGLA_UF"];
                    codigo_estado = shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["CD_UF"];
                    shapeFileBrasil.current["_layers"][shape].setStyle({ weight: 2, color: "black" /*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaWhite*//*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaBrasilWhite/*transparenciaDefaultMapa*/, fillColor: /*'#E63100'*//*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaWhite*/ transparenciaDefaultMapa, fillOpacity: 0.5 });
                } else {
                    shapeFileBrasil.current["_layers"][shape].setStyle({ weight: weightNormal, color: /*transparenciaDefaultMapa*/"black", fillColor: /*transparenciaDefaultMapa*/ "black", fillOpacity: transparencia.current });   
                }
            });
        }

        if(isAllSelect(estadoSelecionado) && !isAllSelect(municipioSelecionado)){
            setMunicipioSelecionado(t("dashboard.todos"));   
           //setCookie("municipioSelecionadoDashboard", t("dashboard.todos"), 0.5);
        } else if(isAllSelect(estadoSelecionado) && isAllSelect(municipioSelecionado)){
            const { innerWidth: width, innerHeight: height } = window;
            const defaultCenter = position;
            
            if(width < 1300)
                map.current.setView([-13.338793, -45.206666], defaultZoom);
            else 
                map.current.setView(defaultCenter, defaultZoom);
            //setCookie("municipioSelecionadoDashboard", municipioSelecionado, 0.5);
        }

        /*console.log("useEffect");*/
        let codMunSelecionado = getCodIbgeCidadeWhereNome(municipioSelecionado);    
        if(shapeFileMunicipios.current !== null){
            Object.keys(shapeFileMunicipios.current["_layers"]).forEach((shape) => {
                let fillOpacity = transparencia.current;
                let corBordaAnt = shapeFileMunicipios.current["_layers"][shape]["options"]["fillColor"];
                let corBordaAtual = shapeFileMunicipios.current["_layers"][shape]["options"]["color"];
                let weightLocal = weightNormal;

                let municipioShapeAtual = shapeFileMunicipios.current["_layers"][shape]["feature"]["properties"]["NM_MUN"];
                let codMunicipioShapeAtual = shapeFileMunicipios.current["_layers"][shape]["feature"]["properties"]["CD_MUN"];

                if(!isAllSelect(municipioSelecionado) && codMunSelecionado === codMunicipioShapeAtual){
                    corBordaAnt = "black";
                    weightLocal = weightSelecao;
                    fillOpacity = opacidadeMunicipio;
                } else if(corBordaAnt === corMapa1){
                    corBordaAnt = theme === "dark" ? corMapa1BordaDark : corMapa1Borda;
                } else if(corBordaAnt === corMapa2){
                    corBordaAnt = theme === "dark" ? corMapa2BordaDark : corMapa2Borda;
                } else if(corBordaAnt === corMapa3){
                    corBordaAnt = theme === "dark" ? corMapa3BordaDark : corMapa3Borda;
                } else if(corBordaAnt === corMapa4){
                    corBordaAnt = theme === "dark" ? corMapa4BordaDark : corMapa4Borda;
                } else if(corBordaAnt === corMapa5){
                    corBordaAnt = theme === "dark" ? corMapa5BordaDark : corMapa5Borda;
                } else {
                    corBordaAnt = theme === "dark" ? cinzaDefaultMapaDark : cinzaDefaultMapaBorda; 
                }

                shapeFileMunicipios.current["_layers"][shape].setStyle({ weight: weightLocal, color: corBordaAnt, fillOpacity: opacidadeMunicipio  });
            });
        }

        if(!isAllSelect(municipioSelecionado)){
            return;
        }

        if(shapeFileBrasil.current !== null){
            setLoading(true);
            if(codigo_estado === 0){
                setListaMunicipios(getCidades());
                let cidades_labels_tmp = [{cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")}];
                setCidades_Labels(cidades_labels_tmp);
            }
            
            carregaDadosEstado(codigo_estado, siglaEstado);
        }
    }, [estadoSelecionado, municipioSelecionado]);

    const getCodIbgeCidadeWhereNome = (cod_ibge_local) => {
        let retorno = null;
        if(!isNumeric(cod_ibge_local) || (cod_ibge_local.length === 6)){
              
            const keys = Object.keys(shapeFileMunicipios.current["_layers"]);
            if (keys.length > 0) {
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    const elementLoop = shapeFileMunicipios.current["_layers"][key];
                    let cod_temp = elementLoop["feature"]["properties"]["CD_MUN"].substring(0, 6);
                    if(elementLoop["feature"]["properties"]["NM_MUN"] === cod_ibge_local || cod_temp === cod_ibge_local){
                        retorno =  elementLoop["feature"]["properties"]["CD_MUN"];
                        break;
                    } 
                }
            } 
        }
        else {
            retorno = cod_ibge_local;
        }
        return retorno;
    }

    const [indice, setIndice] = useState("mob");

    const getNomeCampoIndice = (indice) => {
        return "";
    };

    const preparaInt = (valor) => {
        var valor_temp = valor + "";
        valor_temp = valor_temp.replace(".", "");
        valor_temp = valor_temp.replace(",", "");
        return valor_temp;
    }
    
    const trataNaNeNull = (dado) => {
        return dado === null || dado === "" || dado === "NaN" ? valorNil : dado;
    }

    const tabelaOrdenada = () => {
        //console.log(semanas, dados_Classes);

        if(dados_Classes === null || dados_Classes === undefined || semanas.length === 0) 
            return [];

        /*var tMakers = dados_Classes === undefined ? [] : [...dados_Classes] ;*/
     
        var semanaSelecionada = semanas[semanas.length-1].substring(0, 2);

        var anoSelecionado = semanas[semanas.length-1].substring(3, 7);

        console.log(semanaSelecionada, anoSelecionado);

        var tMakers = [];
        for(let i = 0; i < dados_Classes.length; i++){
            if(String(dados_Classes[i]["epiyear"]) === anoSelecionado && String(dados_Classes[i]["epiweek"]) === semanaSelecionada){
                tMakers.push(dados_Classes[i]);
            }
        }

        if(tMakers.length === 0 || tMakers.undefined){
            return [];
        }

        for(let i=0; i < tMakers.length; i++){
            /*let icodEstado = parseInt(tMakers[i]["cod_ibge"].toString().substring(0,2));
            tMakers[i]["municipio"] = getNomeCidadeWhereCodIbge(tMakers[i]["cod_ibge"]);
            tMakers[i]["estado"] = getNomeEstadoByCodIbge(icodEstado);*/
            if(tMakers[i]["dqi"] === null){
                tMakers[i]["dqi"] = "Não Apto";
            }
            /*if(tMakers[i]["dqi"] === null){
                console.log(tMakers[i]);
            }*/
        } 
        
        var campoOrdemLocal = campoOrdem;
        if (campoOrdemLocal === "num_sinais_arbov" || campoOrdemLocal === "num_sinais_otc" || campoOrdemLocal === "num_sinais_ivas") {
            if (decrescente) {
                tMakers.sort(function (a, b) { return b[campoOrdemLocal] - a[campoOrdemLocal]; });
            } else {
                tMakers.sort(function (a, b) { return a[campoOrdemLocal] - b[campoOrdemLocal]; });
            }
        }
        else
            if (campoOrdemLocal === "classe ibp" || campoOrdemLocal === "estado" || campoOrdemLocal === "dqi") {
                if (decrescente) {
                    tMakers.sort((a, b) => (b[campoOrdemLocal].localeCompare(a[campoOrdemLocal])));
                } else {
                    tMakers.sort((a, b) => (a[campoOrdemLocal].localeCompare(b[campoOrdemLocal])));
                }
            } if(campoOrdemLocal === "classe ibp"){
                if (decrescente) {
                    tMakers.sort((a, b) => (a["num_classe_ibp"] > b["num_classe_ibp"]) ? -1 : 1);
                } else {
                    tMakers.sort((a, b) => (a["num_classe_ibp"] > b["num_classe_ibp"]) ? 1 : -1);
                }
            } else {
                if (decrescente) {
                    tMakers.sort((a, b) => (a[campoOrdemLocal] > b[campoOrdemLocal]) ? -1 : 1);
                } else {
                    tMakers.sort((a, b) => (a[campoOrdemLocal] > b[campoOrdemLocal]) ? 1 : -1);
                }
            }

        if (posicao) {
            setTimeout(function () {
                window.scrollTo(0, posicao);
            }, 1);
        }
    
        //setLoading(false);
        return tMakers;
    }

    const carregaDadosEstado = (codigo_estado, siglaEstado) => {
        if(codigo_estado !== 0){
            const keys = Object.keys(shapeFileBrasil.current["_layers"]);
            if (keys.length > 0) {
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    const element = shapeFileBrasil.current["_layers"][key];
                    if(element !== undefined && element["feature"]["properties"]["NM_UF"] === estadoSelecionado){
                        map.current.fitBounds(element.getBounds());
                        break;
                    } 
                }
            } 
            carregaMunicipiosPorEstado(siglaEstado);
        }
        setLoading(false);         
    }

    function carregaMunicipiosPorEstado(siglaEstado){
        if(!loading){
            setLoading(true);
        }
        
        if(siglaEstado !== ''){
            fetch('Dados/lista_municipios/lista_municipios_'+siglaEstado+'.json?' + dataDados
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
            .then(function (response) {
                //Limpa pontos anteriores
                return response.json();
            })
            .then(function (myJson) {
                let listaTemp = [];
                let cidades_labels_tmp = [];
                for(let i = 0; i < myJson.length; i++){
                    listaTemp.push({
                        "cod_ibge": myJson[i]["COD_IBGE"],
                        "municipio": myJson[i]["MUNICIPIO"],
                        "latitude": myJson[i]["Y"],
                        "longitude": myJson[i]["X"]
                    });

                    cidades_labels_tmp.push({cod_ibge: myJson[i]["COD_IBGE"],  label: myJson[i]["MUNICIPIO"]});
                }

                cidades_labels_tmp.sort((a, b) => a.label.localeCompare(b.label));

                cidades_labels_tmp.unshift({cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")});//todos

                setCidades_Labels(cidades_labels_tmp);

                listaTemp.sort((a, b) => a["municipio"].localeCompare(b["municipio"]));
                
                listaTemp.unshift({
                    "cod_ibge": 0,
                    "municipio": t("dashboard.todos")
                });

                setListaMunicipios(listaTemp); 
                
                //revisar
                //getDadosPorData(datas[idxDataSelecionada], language, true);
            });
        }
    }

    const handleChangeMunicipioTabela = (event) => {
        setMunicipioSelecionado(event.target.value);
    }

    const handleChangeEstadoTabela = (event) => {
        setEstadoSelecionado(event.target.value);
    }

    useEffect(() => {
        fetch('Dados/aesop_mun_classes_ibp.json?' + dataDados, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            setDados_Classes(myJson);

            if(semanas.length !== 0){
                var semanaSelecionada = semanas[semanas.length-1].substring(0, 2);

                var anoSelecionado = semanas[semanas.length-1].substring(3, 7);

                console.log(semanaSelecionada, anoSelecionado, myJson);

                var tMakers = [];
                for(let i = 0; i < myJson.length; i++){
                    console.log(String(myJson[i]["epiyear"]) === anoSelecionado, String(myJson[i]["epiweek"]) === semanaSelecionada, anoSelecionado, semanaSelecionada);
                    if(String(myJson[i]["epiyear"]) === anoSelecionado && String(myJson[i]["epiweek"]) === semanaSelecionada){
                        tMakers.push(myJson[i]);
                        switch(myJson[i]["num_classe_ibp"]){
                            case 1:
                                contadorFaixa1.current = myJson[i]["num_sinais_ivas"];
                                break;
                            case 2:
                                contadorFaixa2.current = myJson[i]["num_sinais_ivas"];
                                break;
                            case 3:
                                contadorFaixa3.current = myJson[i]["num_sinais_ivas"];
                                break;
                            case 4:
                                contadorFaixa4.current = myJson[i]["num_sinais_ivas"];
                                break;
                            case 5:
                                contadorFaixa5.current = myJson[i]["num_sinais_ivas"];
                                break; 
                        }
                    }
                }
            }

            setAtualizouContadores(true);
            /*console.log(myJson);
            for(let i = 0; i < myJson.length; i++){
                console.log(myJson[i]);
            }*/
        });
    }, [semanas]);

    return (
        <Container className="container_Mapa">
            {loading && <div className={style.loading}><div className={style.lds_ring}><div></div><div></div><div></div><div></div></div></div>}

            <Row>
                <TabNav theme={theme} language={language} currentPage={"IBP"} link_gripe="" link_zika= {link_zika}/>

                <Row>
                    <div className={style.div_top}>
                        <br></br>
                        <div className='titulo_semana_ep' style={{marginBottom:"10px"}}>
                            <Translator path="dashboard.semana_ep" />: {semanas[semanas.length-1]} 
                        </div>
                        <div className={style.container_seleciona_municipio_e_cor}>
                            <div className={style.seleciona_municipio}> 
                                <Autocomplete
                                    {...defaultProps}
                                    disableClearable
                                    id="autocomplete_estado"
                                    className='change_color_text'
                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                    value={{label:estadoSelecionado}}
                                    onChange={(event, newValue) => {
                                        setLoading(true);
                                        setEstadoSelecionado(newValue.label);
                                        setCod_Ibge(t("dashboard.todos"));
                                        setMunicipioSelecionado(t("dashboard.todos"));
                                    }}
                                    renderInput={(params) => (
                                        <TextField className='autocomplete_estado_textfield' {...params} label={t("dashboard.estado")} size="small"/>
                                    )}
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                
                                <Autocomplete
                                    {...defaultPropsMunicipio}
                                    disableClearable
                                    id="autocomplete_municipio"
                                    className='change_color_text'
                                    isOptionEqualToValue={(option, value) => option.cod_ibge === value.cod_ibge}
                                    value={{cod_ibge:cod_ibge, label:municipioSelecionado}}
                                    onChange={(event, newValue) => {
                                        handleChangeMunicipioNome(newValue.cod_ibge);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t("dashboard.cidade")} size="small"/>
                                    )}
                                />
  
                                
                                {/*
                                <FormControl id="controle-selecao-indicador">
                                    <InputLabel id="demo-simple-select-label">{"ColorMap"}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={mapa_cor_value}
                                        onChange={(event) => setMapa_Cor_Value(event.target.value)}
                                        label={"ColorMap"}
                                        name={"sinais_aviso"}
                                        sx={{ width: 200, height: 32}}
                                    >
                                    {listaColorMap.map((e, key) => {
                                        return (
                                            <MenuItem key={key} value={e["value"]}>{e["nome"]}</MenuItem>
                                        );
                                    })}
                                    </Select>
                                </FormControl>  */}
                            </div>
                        </div>
                    </div>
                </Row>
                
                <Row>
                    <Col md={12}>
                        <div className={style.mapa}>
                            <div id="map" className={style.map}>

                            </div>
                        </div>
                    </Col>
                </Row>
                <div className='espacamento'></div>

                <div className={style.table}>
                    <div className={style.header_tabela}>
                        <div className={style.titulo_tabela}><h5><center>IBP</center></h5></div>
                        <div className={style.export_buttons}>
                            <div className='divExportPlanilhaCSV'>
                                <img className="exportPlanilhaCSV" src='/imagens/csv_logo.gif' onClick={() => {exportToCSV("dtBasicExampleRank", "rank")}}></img>
                                <div className="balaoDeTextoCsv noHover">Download CSV</div>
                            </div>
                            <div className={style.div_export_planilha_excel}>
                                <img className={style.export_planilha} src='/imagens/excel_logo.gif' onClick={() => {exportToExcel("dtBasicExampleRank", "rank")}}></img>
                                <div className={style.balao_de_texto_excel}>Download XLSX</div>        
                            </div>
                        </div>
                    </div>
                    <Table id={"dtBasicExampleRank"} striped bordered hover variant={theme === "dark" ? "dark" : ""}>
                        <thead>
                            <tr>
                                <th><a href='#' style={{ color: campoOrdem === "classe ibp" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdem ===  "classe ibp") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                        setCampoOrdem( "classe ibp")
                                    }}>
                                    <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> <Translator path={"dashboard.table.classe_ibp"} /></a>
                                </th>

                                <th><a href='#' style={{ color: campoOrdem === "epiyear" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdem === "epiyear") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                        setCampoOrdem("epiyear")
                                    }}>
                                    <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> <Translator path={"dashboard.ano"} /></a>
                                </th>

                                <th><a href='#' style={{ color: campoOrdem === "epiweek" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdem === "epiweek") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                        setCampoOrdem("epiweek")
                                    }}> 
                                    <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.semana_ep"} /> </a>
                                </th>

                                <th><a href='#' style={{ color: campoOrdem === "num_sinais_ivas" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "num_sinais_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("num_sinais_ivas")
                                    }}> 
                                    <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.num_sinais_ensamble"} /> </a>
                                </th>

                                <th><a href='#' style={{ color: campoOrdem === "atend_ivas" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdem === "atend_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                        setCampoOrdem("atend_ivas")
                                        }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.col7"} />  
                                    </a>
                                </th>   

                                <th><a href='#' style={{ color: campoOrdem === "atend_totais" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdem === "atend_totais") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                        setCampoOrdem("atend_totais")
                                        }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.col8"} />  
                                    </a>
                                </th> 

                                <th><a href='#' style={{ color: campoOrdem === "prop_ivas" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdem === "prop_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                        setCampoOrdem("prop_ivas")
                                        }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.col6"} /> (%)   
                                    </a>
                                </th> 

                                <th><a href='#' style={{ color: campoOrdem === "media_movel_ivas" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdem === "media_movel_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                        setCampoOrdem("media_movel_ivas")
                                        }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.media_movel_proporcao"} /> (%)  
                                    </a>
                                </th> 
                            </tr>
                        </thead>
                        <tbody>
                            {tabelaOrdenada().map((value, index, array) => {
                                const isMesmoEstado = index < array.length - 1 && value["cod_uf"] === array[index + 1]["cod_uf"];
                                return (
                                    <tr key={index}>
                                        <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeEstadoTabela(value["classe ibp"]) }}>{ /*isMesmoEstado && index > 0 ? "" : */value["classe ibp"]}</a></td>
                                        <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeMunicipioTabela(value["epiyear"]) }}>{value["epiyear"]}</a></td>
                                        <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeMunicipioTabela(value["epiweek"]) }}>{value["epiweek"]}</a></td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["num_sinais_ivas"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["atend_ivas"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["atend_totais"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{fixaCasasDecimais(trataNaNeNull(value["prop_ivas"]),2)}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{fixaCasasDecimais(trataNaNeNull(value["media_movel_ivas"]),2)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <Table id={"dtBasicExampleRankExport"} style={{display:"none"}} striped bordered hover variant={theme === "dark" ? "dark" : ""}>
                        <thead>
                            <tr>
                                <th><a href='#' style={{ color: campoOrdem === "estado" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "estado") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("estado")
                                }}>
                                <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> <Translator path={"dashboard.table.estado"} /></a>
                                </th>

                                <th><a href='#' style={{ color: campoOrdem === "municipio" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdem === "municipio") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                        setCampoOrdem("municipio")
                                    }}>
                                    <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> <Translator path={"dashboard.table.col1"} /></a>
                                </th>                    

                                <th>rank_den</th>
                                <th>rank_dsei</th>
                                <th>rank_ibp</th>
                                <th>rank_mob</th>
                                <th>rank_mob_den</th>
                                <th>rank_mob_den_dsei</th>
                                <th>rank_mob_den_ibp</th>
                                <th>rank_mob_den_ibp_dsei</th>
                                <th>rank_mob_dsei</th>
                                <th>rank_mob_ibp</th>
                                <th>rank_mob_ibp_dsei</th>

                                <th><a href='#' style={{ color: campoOrdem === "dqi" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "dqi") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("dqi")
                                }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> DQI </a></th>

                                <th>
                                    <a href='#' style={{ color: campoOrdem === "sinal_ens_ivas" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdem === "sinal_ens_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                        setCampoOrdem("sinal_ens_ivas")
                                        }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.sinal_ens_atual"} />  
                                    </a>
                                </th>  
                            </tr>
                        </thead>
                        <tbody>
                            {tabelaOrdenada().map((value, index, array) => {
                                const isMesmoEstado = index < array.length - 1 && value["cod_uf"] === array[index + 1]["cod_uf"];
                            
                                return (
                                    <tr key={index}>
                                        <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeEstadoTabela(value["estado"]) }}>{ /*isMesmoEstado && index > 0 ? "" : */value["estado"]}</a></td>
                                        <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeMunicipioTabela(value["cod_ibge"]) }}>{value["municipio"]}</a></td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_den"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_dsei"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_ibp"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_mob"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_mob_den"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_mob_den_dsei"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_mob_den_ibp"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_mob_den_ibp_dsei"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_mob_dsei"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_mob_ibp"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["rank_mob_ibp_dsei"]))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["dqi"]) === "Apto" ?  t("dashboard.apto"): t("dashboard.nao_apto"))}</td>
                                        <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["sinal_ens_ivas"]))}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            </Row>
        </Container>

    );
}

export default IBP;